.restaurant-menu {
  &__image-style {
    display: block;
  }

  // TODO: Delete after removing `site.features.sticky_nav` flag & Add proper styles changes in other modules https://restaumatic.atlassian.net/browse/B2C-907
  &--sticky-tabs-mobile {
    .m-flex-layout__aside {
      &--tabs {
        @media screen and (max-width: $grid-float-breakpoint-max) {
          display: block;
        }
      }

      &:not(.m-flex-layout__aside--tabs) {
        @media screen and (max-width: $grid-float-breakpoint-max) {
          display: none;
        }
      }
    }

    // Force mobile styling of section title for sticky tabs variant testing purposes
    .m-list__title {
      @media screen and (max-width: $grid-float-breakpoint-max) {
        display: block;
        margin: 0;
        text-align: start;
        text-transform: revert;
        // Old themes support
        @if variable-exists(m-item-title-font-size) {
          font-size: $m-item-title-font-size * 1.25;
        } @else {
          font-size: 24px;
        }

        &.m-list__title--inline-block {
          padding-inline: 0;
          background-color: unset;
          transform: none;
          color: var(--color-headings);
        }
      }
    }

    .m-group__header {
      @media screen and (max-width: $grid-float-breakpoint-max) {
        display: none;
      }
    }
  }

  &--sticky-tabs-desktop {
    .m-flex-layout__aside {
      &--tabs {
        @media screen and (min-width: $grid-float-breakpoint) {
          display: block;
        }
      }

      &:not(.m-flex-layout__aside--tabs) {
        @media screen and (min-width: $grid-float-breakpoint) {
          display: none;
        }
      }
    }
  }

  &--sticky-tabs-mobile,
  &--sticky-tabs-desktop {
    .m-group__list-item {
      display: block;
      scroll-margin-top: var(--sticky-tabs-height, 60px);
    }
  }
}
