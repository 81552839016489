$m-info-box-image-overlap-value: 200px;

.m-info-box {
  &__image {
    margin-bottom: $grid-gutter-width;
    border: 17px solid #fff;

    @media (min-width: $screen-sm-min) {
      margin-bottom: 0;
    }

    @media (min-width: $screen-md-min) {
      @include pasja-box-shadow();
      margin-top: -$m-info-box-image-overlap-value;
      margin-left: $grid-gutter-width / -1;
    }

    .m-info-box--cols-reverse & {
      @media (min-width: $screen-sm-min) {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  &__content {
    @media (min-width: $screen-sm-min) {
      padding-top: $grid-gutter-width;
    }

    @media (min-width: $screen-md-min) {
      margin-left: $grid-gutter-width / -1;
      padding-right: $grid-gutter-width * 1.5;
    }

    .m-info-box--cols-reverse & {
      @media (min-width: $screen-sm-min) {
        padding-left: $grid-gutter-width * 1.5;
      }
    }
  }

  &__col-media {
    .m-info-box--cols-reverse & {
      @media (min-width: $screen-sm-min) {
        order: 10;
      }
    }
  }
}
