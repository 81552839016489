// Modifier item
// Used in product creator, to display modifier items in ModifierList display mode

// 1. Hardcoded value similar to `creator.scss` or Bootstrap defaults (TODO: consider refactor depending on final design).

.m-modifier-item {
  display: flex;
  flex-wrap: wrap;
  margin-right: 5px; // [1]
  margin-bottom: 10px; // [1]
  align-items: center;
  padding: 4px 15px 4px 0; // [1]

  &__stepper {
    display: inline-flex;
    align-items: center;
  }

  &__name {
    display: flex;
    align-items: center;
    margin: 5px 0 0 0;
    width: 100%;

    @media screen and (min-width: $screen-xs-min) {
      width: auto;
      margin: 0 0 0 10px;
    }
  }

  $grid-spacing: 5px; // [1]

  // Display numeric modifier items like a "grid" on the desktop
  &--wrapping {
    display: flex;
    flex-wrap: nowrap;
    margin: 0 0 #{$grid-spacing} 0;
    width: 100%;
    min-height: 50px;
    align-items: center;
    padding: 4px 15px; // [1]
    border-radius: 4px;
    border: 1px solid #d8d8d8; // [1]
    background-color: #fafafa; // [1]

    @media screen and (min-width: $screen-xs-min) {
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: calc(33% - #{$grid-spacing});
      margin: 0 #{$grid-spacing} #{$grid-spacing} 0;
    }

    &.is-selected {
      background-color: $creator-item-selected-bg-color;
    }

    .m-modifier-item__stepper {
      order: 1;
      margin-right: 10px; // [1]

      @media screen and (min-width: $screen-xs-min) {
        order: 2;
        margin-right: 0; // [1]
      }
    }

    .topping__name {
      flex: 1 0;
      display: flex;
      align-items: center;
      order: 2;

      & > span {
        word-wrap: break-word;
        hyphens: auto;
      }

      @media screen and (min-width: $screen-xs-min) {
        order: 1;
        margin: 5px 0; // [1]

        & > span:first-child() {
          text-align: center; // center text if no modifier image
        }
      }
    }
  }
}
