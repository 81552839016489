//
// Useful features from upcoming Bootstrap 4.
//
// Backward compatibility is required, so new features can't interfere with existing classes.
// Extra caution is needed until full migration and stable release.
//
// TODO: Implement corresponding mixins.
// ------------------------------------------------------------------------------------------

// Responsive floats | https://v4-alpha.getbootstrap.com/utilities/responsive-helpers/#responsive-floats
// .pull-left and .pull-right are redundant to .float-left and .float-right

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: $screen-sm-min) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: $screen-md-min) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}

@media (min-width: $screen-lg-min) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}

// Flexbox | https://v4-alpha.getbootstrap.com/utilities/flexbox/
// TODO: Import all responsive flexbox utilities
.d-flex {
  display: flex !important;
}

.d-xs-flex {
  @media (min-width: $screen-xs-min) {
    display: flex !important;
  }
}

.d-sm-flex {
  @media (min-width: $screen-sm-min) {
    display: flex !important;
  }
}

.d-inline-flex {
  display: inline-flex !important;
}

// Flexbox - justify content
// TODO: add responsive variations
.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

// Flexbox - align items
// TODO: add responsive variations
.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

// Direction
.flex-column {
  flex-direction: column !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

@media (min-width: $screen-sm-min) {
  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
}

@media (min-width: $screen-md-min) {
  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
}

@media (min-width: $screen-lg-min) {
  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
}

// Wrap
// TODO: add responsive variations
.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}
