// List
$m-group-list-is-unstyled: true !default;

// Header
$m-group-header-font-size: null !default;
$m-group-header-text-transform: null !default;
$m-group-header-font-weight: null !default;

// Link
$m-group-header-link-padding: 0.7em !default;
$m-group-header-link-color: inherit !default;
$m-group-header-link-background: transparent !default;
$m-group-header-link-text-align: left !default;

// Link hover
$m-group-header-link-hover-color: inherit !default;
$m-group-header-link-hover-background: transparent !default;

.m-group {
  &__list {
    @if ($m-group-list-is-unstyled == true) {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }

  &__list-item {
    .m-list {
      @media screen and (max-width: $grid-float-breakpoint-max) {
        animation: fadeIn $base-duration;
      }
    }

    &--tab {
      display: none;

      &.is-active {
        display: block;

        .m-list {
          animation: fadeIn $base-duration;
        }
      }
    }

    &--accordion {
      display: none;

      &.is-active {
        display: block;

        .m-list {
          animation: fadeIn $base-duration;
        }
      }
    }

    &--list {
      display: none;

      &.is-active {
        display: block;
      }
    }

    &--list-all {
      @media screen and (max-width: $grid-float-breakpoint-max) {
        display: none;

        &.is-active {
          display: block;
        }
      }
    }

    &--separate-item {
      @media screen and (min-width: $grid-float-breakpoint) {
        margin-bottom: floor($grid-gutter-width * 2);
      }
    }
  }

  &__header {
    margin: 0;
    font-size: $m-group-header-font-size;
    font-weight: $m-group-header-font-weight;
    text-transform: $m-group-header-text-transform;
    perspective: 1000px; // Fix content blinking on Safari WebKit engine (RS-6406)

    &--mobile {
      @media screen and (min-width: $grid-float-breakpoint) {
        display: none;

        .l-mobile-app--ios & {
          display: block;
        }
      }
    }

    &--not-mobile {
      @media screen and (max-width: $grid-float-breakpoint-max) {
        display: none;
      }
    }

    &:not(:first-child) {
      border-top: 1px solid rgba(#000, 0.1);
    }
  }

  &__header-link {
    display: flex;
    padding-top: $m-group-header-link-padding;
    padding-bottom: $m-group-header-link-padding;
    background: $m-group-header-link-background;
    color: $m-group-header-link-color;
    text-align: $m-group-header-link-text-align;
    transition: background-color $base-duration;
    align-items: center;

    &.is-active,
    &:hover,
    &:focus {
      background: $m-group-header-link-hover-background;
      color: $m-group-header-link-hover-color;
      text-decoration: none;
    }

    &.is-unavailable {
      opacity: 0.5
    }
  }

  &__header-image {
    margin-right: 0.5em;
    flex-shrink: 0;
  }

  &__header-text {
    flex-grow: 1;
  }

  &__title {
    @extend .h2;
  }

  &__toggle-icon {
    @include icon();
    @extend .icon-chevron-down;
    margin: 0;
    transition: transform .3s;
    flex-shrink: 0;

    @at-root .m-group__header-link.is-active {
      perspective: 1000px; // fix issue with disappearance of the rotated element in iOS

      .m-group__toggle-icon {
        transform: rotateX(180deg);
      }
    }
  }
}

.no-js {
  .m-group {
    &__list-item {
      &--tab,
      &--accordion {
        display: block;
      }
    }
  }
}
