// Checkout module doesn't follow BEM guidelines because of the limited control over markup and shared code for all themes.

.m-checkout {
  font-size: $font-size-small-unit;

  &__cols {
    display: flex;
    flex-flow: column nowrap;

    @media screen and (min-width: $screen-md-min) {
      flex-flow: row nowrap;
      align-items: flex-start;
    }
  }

  .section-subheading {
    @extend .m-content__title;
  }

  .column-form {
    @extend .m-content__content;
    margin-right: ($grid-gutter-width);
    width: 100%;
    order: 2;

    @media screen and (min-width: $screen-md-min) {
      width: 58%;
      flex: 1 1 58%;
    }
  }

  .column-summary {
    @extend .m-content__content;
    width: 100%;
    order: 1;

    @media screen and (min-width: $screen-md-min) {
      width: 42%;
      flex: 1 1 42%;
      order: 3;
    }

    .highlight {
      font-weight: $font-weight-bold;
      font-size: 120%;
      //color: $headings-color; // defined in theme.scss.liquid
    }

    .total-value {
      text-align: right;
      white-space: nowrap;
    }
  }

  .card {
    padding: 0;
    background-color: transparent;
    box-shadow: none;
  }

  .summary-view {
    margin-bottom: 0;

    th {
      border-bottom: none;
      //color: $headings-color; // defined in theme.scss.liquid
    }

    th,
    td {
      text-align: center;

      &:first-child {
        padding-left: 0;
        text-align: left;
      }

      &:last-child {
        padding-right: 0;
        text-align: right;
      }
    }
  }

  abbr[title="required"] {
    margin-right: 5px;
    margin-left: 5px;
    color: $brand-danger;
  }

  section {
    margin-bottom: $base-spacing;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .control-label {
    &:not(.m-checkbox):not(.m-radio) {
      //color: $headings-color; // defined in theme.scss.liquid
    }
  }

  .form-actions {
    [type="submit"] {
      @include button-size(
        $btn-padding-large-vertical,
        $btn-padding-large-horizontal,
        $btn-font-size-large,
        $btn-line-height-large,
        $btn-border-radius-large
      );
      display: block;
      width: 100%;
      margin-top: $base-spacing;
    }
  }
}
