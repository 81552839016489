.m-icon-group {
  &__list {
    margin: 0;
    padding-left: 0;
    list-style: none;
  }

  &__list-item {
    display: inline-block;
    vertical-align: middle;

    &:not(:first-child) {
      padding-left: 5px;
    }
  }

  &__item-link {
    display: block;
    color: inherit;

    &:hover,
    &:focus {
      //color: $link-color; // defined in theme.scss.liquid
      text-decoration: none;
    }
  }

  &__item-label {
    margin-left: ceil($grid-gutter-width / 2);
    color: inherit
  }

  [class*="icon"] {
    vertical-align: middle;
  }
}
