.m-slider {
  position: relative;
  overflow: hidden;

  &--vertical {
    .m-slider__featured {
      @media screen and (min-width: $grid-float-breakpoint) {
        flex: 0 0 auto;
        max-width: 66%;
      }
    }

    .m-slider__slide-content {
      @media screen and (min-width: $grid-float-breakpoint) {
        margin: 120px 0 $pasja-box-shadow-offset-base -140px;
        width: 100%;
      }

      @media screen and (min-width: $screen-lg-min) {
        margin-left: -60px;
      }
    }
  }


  &--horizontal {
    .m-slider__slide-content {
      @media screen and (min-width: $grid-float-breakpoint) {
        margin: -50% 15px $pasja-box-shadow-offset-base 20vh;
      }
    }
  }

  &--horizontal-wide {
    .m-slider__featured {
      @media screen and (min-width: $grid-float-breakpoint) {
        flex: 0 0 auto;
        max-width: 80%;
      }

      @media screen and (min-width: $screen-lg-min) {
        max-width: 70%;
      }
    }

    .m-slider__slide-content {
      @media screen and (min-width: $grid-float-breakpoint) {
        margin: 120px 0 $pasja-box-shadow-offset-base -55vw;
        width: 100%;
      }

      @media screen and (min-width: $screen-lg-min) {
        margin-left: -120px;
      }
    }
  }

  &--fullwidth {
    max-width: 100vw;

    .m-slider__featured {
      @include position(absolute, 0 0 null 0);
    }

    .m-slider__slide-content {
      @media (min-width: $screen-sm-min) {
        margin-right: auto;
        margin-left: auto;
        max-width: $container-sm;
      }

      @media (min-width: $screen-md-min) {
        max-width: $container-md;
      }

      @media (min-width: $screen-lg-min) {
        max-width: $container-lg;
      }
    }
  }

  &--hero {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;

    // Wider variant for hero image
    //.container {
    //  @media (min-width: $screen-xlg-min) {
    //    width: $screen-xlg-min - $grid-gutter-width * 2;
    //  }
    //}

    // Center slides even if different slides have very different height
    .m-slider__list,
    .splide__list {
      display: flex;
      align-items: center;
    }
  }

  &__list {
    @include list-unstyled;
    @include clearfix;
    margin: 0;
  }

  &__list-item {
    position: relative;
    min-height: 1px;
    overflow: hidden;
    width: 100%;
  }

  &__item-inner {
    @media screen and (max-width: $grid-float-breakpoint-max) {
      padding: 120px 15px $pasja-box-shadow-offset-base 30px;
    }

    @at-root .m-slider--hero & {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 50px 0;
    }

    @at-root .m-slider--vertical & {
      @media screen and (min-width: $grid-float-breakpoint) {
        display: flex;
        align-items: flex-start;
      }
    }

    @at-root .m-slider--horizontal-wide & {
      @media screen and (min-width: $grid-float-breakpoint) {
        display: flex;
        align-items: flex-start;
      }
    }

    @at-root .m-slider--fullwidth & {
      padding: 120px 15px $pasja-box-shadow-offset-base 30px;

      @media (min-width: $grid-float-breakpoint) {
        padding-top: 220px;
      }
    }

    &--media-next {
      @media (min-width: $grid-float-breakpoint) {
        text-align: left;
      }
    }

    &--rich-header {
      @at-root .m-slider--fullwidth & {
        padding-top: 260px;

        @media (min-width: $grid-float-breakpoint) {
          padding-top: 420px;
        }
      }
    }
  }

  &__hero-picture {
    display: none;
    width: 50%;

    @media (min-width: $grid-float-breakpoint) {
      display: block;
    }
  }

  &__hero-image {
    margin-right: auto;
    margin-left: auto;
    padding-left: $grid-gutter-width;
    max-width: 120%;

    @media (min-width: $screen-xlg-min) {
      max-width: none;
    }
  }

  &__featured {
    position: relative;
    display: inline-block;

    @media screen and (max-width: $grid-float-breakpoint-max) {
      @include position(absolute, 0 0 null 0);
    }
  }

  &__slide-img {
    //background-color: $body-bg; // defined in theme.scss.liquid
    position: relative;
  }

  &__image {
    @include pasja-blend-mode();

    &--rich-header {
      width: 100%;
      height: 500px;
      object-fit: cover;
    }
  }

  &__vertical-video {
    max-width: 100%;
    min-height: 250px;
    max-height: 820px;
    object-fit: contain;
  }

  &__slide-content {
    @include pasja-box-shadow();
    position: relative;
    padding: 30px;
    background-color: #fff;

    @media screen and (min-width: $screen-xs-min) {
      padding: 39px;
    }

    @media screen and (min-width: $grid-float-breakpoint) {
      padding: 78px;
    }

    @at-root .m-slider--hero & {
      padding: 0;
      width: 50%;
      flex-grow: 1;
      background-color: transparent;
      border: none;
      box-shadow: none;
      color: #fff;
    }
  }

  &__indicators {
    @include position(absolute, 25px null null 30px);
  }

  &__arrows {
    @include position(absolute, 25px 30px null null);
    z-index: $zindex-ui;
    color: #fff;
  }

  &__sup-title {
    font-size: $font-size-small;
  }

  &__title {
    margin-top: 0;
    margin-bottom: $base-spacing;
    font-size: 1.25rem;
    text-transform: uppercase;

    @at-root .l-page-header-ls & {
      letter-spacing: $pasja-letter-spacing;
    }

    @media screen and (min-width: $grid-float-breakpoint) {
      font-size: 2.25rem;
    }

    @at-root .m-slider--hero & {
      color: inherit;
    }

    &:after {
      display: block;
      width: 60px;
      height: 5px;
      margin-top: $base-spacing;
      background-color: rgba(0, 0, 0, 0.04);
      content: " ";

      @at-root .m-slider--hero & {
        display: none;
      }
    }
  }

  &__description {
    margin-bottom: $base-spacing;
  }

  &__action {
    margin-bottom: 0;
  }

  &__logo {
    display: inline-block;
    margin: 50px auto 0;

    @at-root .m-slider--fullwidth & {
      $max-height: 120px;
      $max-height-gfb: 200px;

      max-height: 100px;
      top: floor($grid-gutter-height / -2);
      z-index: $zindex-ui;

      @media screen and (min-width: $grid-float-breakpoint) {
        max-height: 200px;
      }

      .m-brand__logo--limited {
        max-height: $max-height;

        @media screen and (min-width: $grid-float-breakpoint) {
          max-height: $max-height-gfb;
        }
      }
    }

    &--absolute-center {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      z-index: $zindex-ui;
    }

    &--center {
      display: block;
      text-align: center;

      .m-brand__logo {
        margin-right: auto;
        margin-left: auto;
      }
    }

    @at-root .m-slider__rich-header .m-slider__nav + & {
      margin-top: 25px;
    }
  }

  &__rich-header {
    position: absolute;
    top: 45px;
    left: 0;
    right: 0;
    margin-top: floor($grid-gutter-width / 2);
    text-align: center;
    z-index: $zindex-ui;

    @media screen and (min-width: $grid-float-breakpoint) {
      top: 30px;
    }

    &--top-mobile {
      @media screen and (max-width: $grid-float-breakpoint-max) {
        top: 0;
        margin-top: 0;
      }
    }
  }
}
