$m-map-margin: null !default;
$m-map-width: 100% !default;
$m-map-height: 350px !default;
$m-map-embed-border: none !default;

.m-map {
  margin: $m-map-margin;
  height: $m-map-height;
  width: $m-map-width;

  &__embed {
    width: 100%;
    height: 100%;
    border: $m-map-embed-border;
  }

  &--one-page-section {
    width: 100%;
    margin: 0 auto;
    max-width: 1920px;
  }
}
