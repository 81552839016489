$m-nav-gutter: 30px !default;

.m-nav {
  &__list {
    margin: 0;
    padding: 0 ceil($m-nav-gutter / 2);
    list-style: none;
  }

  &__list-item {
    display: inline-block;
    text-transform: uppercase;
  }

  &__item {
    display: block;
    padding: ceil($m-nav-gutter / 6) ceil($m-nav-gutter / 4);

    &.is-active,
    &:hover,
    &:focus {
      //color: $brand-primary; // defined in theme.scss.liquid
      text-decoration: none;
    }
  }

  &__dropdown-list {
    @include pasja-box-shadow(10px);
    display: none;
    margin: 20px 0 0;
    padding-left: 0;
    list-style: none;
    text-align: left;
    animation: fadeIn $base-duration;
  }

  &__dropdown-item {
    &.is-active,
    &:hover,
    &:focus {
      //color: $link-color; // defined in theme.scss.liquid
      text-decoration: none;
    }
  }
}
