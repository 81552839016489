.m-footer-info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 100%;

  &__terms {
    display: inline-block;
    margin: 2px 0;
  }

  &__link {
    color: inherit;
  }

  &__info {
    display: inline-block;
    margin: 2px 0;

    @media screen and (min-width: $grid-float-breakpoint) {
      flex-grow: 1;
      text-align: right;
    }
  }
}
