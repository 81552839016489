$m-group-nav-font-size: null !default;
$m-group-nav-font-weight: null !default;
$m-group-nav-dropdown-shadow: 0 6px 12px rgba(#000, 0.175) !default;
$m-group-nav-dropdown-base-width: 250px !default;
$m-group-nav-dropdown-max-width: 60vw !default;
$m-group-nav-dropdown-white-space: normal !default;
$m-group-nav-background: transparent !default;
$m-group-nav-text-transform: null !default;
$m-group-nav-aside-link-border: null !default;
$m-group-nav-sticky-zindex: $zindex-ui - 10 !default; // in most cases it should be lower than other elements in the stacking context in order to avoid possible overlap bugs (example bug: https://trello.com/c/X5ahrhR5)

// Title
$m-group-nav-title-padding: null !default;
$m-group-nav-title-font-size: null !default;

// List
$m-group-nav-list-is-unstyled: true !default;

// Arrow
$m-group-nav-arrow-size: 30px !default;
$m-group-nav-arrow-padding: 4px !default;
$m-group-nav-arrow-bg-color: var(--color-body-bg, #fff) !default;

// Link
$m-group-nav-link-padding: 0.7em !default;
$m-group-nav-link-color: inherit !default;
$m-group-nav-link-background: transparent !default;
$m-group-nav-tabs-link-padding: map-get($spacers, 4) !default;
$m-group-nav-tabs-link-border-width: 4px;

// Link hover
$m-group-nav-link-hover-color: inherit !default;
$m-group-nav-link-hover-background: transparent !default;
$m-group-nav-link-hover-font-weight: null !default;

.m-group-nav {
  background: $m-group-nav-background;
  font-size: $m-group-nav-font-size;
  font-weight: $m-group-nav-font-weight;
  text-transform: $m-group-nav-text-transform;

  &__title {
    margin-top: 0;
    padding-top: $m-group-nav-title-padding;
    padding-bottom: $m-group-nav-title-padding;
    font-size: $m-group-nav-title-font-size;
  }

  &__list {
    @if ($m-group-nav-list-is-unstyled == true) {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    &--horizontal {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      text-align: center;
    }

    &--dropdown {
      @include list-unstyled; // override default ul
      @include position(absolute, 100% null null 0);
      z-index: $zindex-dropdown;
      display: none; // none by default, but block on "open" of the menu
      float: left;
      overflow: auto;
      margin-top: 2px;
      width: $m-group-nav-dropdown-base-width;
      max-width: $m-group-nav-dropdown-max-width;
      max-height: 80vh;
      white-space: $m-group-nav-dropdown-white-space;
      border-radius: $border-radius-base;
      background-color: $dropdown-bg;
      box-shadow: $m-group-nav-dropdown-shadow;
    }

    &--dropdown-menu-right {
      right: 0;
      left: auto;
    }

    &--tabs {
      @include hide-scrollbar();
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      text-align: center;
      scroll-snap-type: x mandatory;
      scroll-behavior: smooth;
    }
  }

  &__arrow {
    display: flex;
    position: absolute;
    height: 100%;
    align-items: center;
    visibility: collapse;
    transition-property: transform, visibility;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
    padding-inline: $m-group-nav-arrow-padding;

    &.is-visible {
      transform: translate(0);
      visibility: visible;
      z-index: 1;
    }

    &--prev {
      left: 0;
      transform: translate(-100%);
      background: linear-gradient(
        to right,
        $m-group-nav-arrow-bg-color 20%,
        transparent 100%
      );
    }

    &--next {
      right: 0;
      transform: translate(100%);
      background: linear-gradient(
        to right,
        transparent 0%,
        $m-group-nav-arrow-bg-color 80%
      );
    }
  }

  &__arrow-btn {
    width: $m-group-nav-arrow-size;
    height: $m-group-nav-arrow-size;
    background-color: $m-group-nav-arrow-bg-color;
    border: 1px solid var(--color-separator);
    // border-radius: $btn-border-radius-base;
    border-radius: 50%;
    font-size: 14px;
  }

  &__link {
    display: block;
    padding-top: $m-group-nav-link-padding;
    padding-bottom: $m-group-nav-link-padding;
    background: $m-group-nav-link-background;
    color: $m-group-nav-link-color;
    border: none;

    @media screen and (min-width: $grid-float-breakpoint) {
      scroll-snap-align: start;
      scroll-snap-stop: always;
      scroll-margin-left: $m-group-nav-arrow-size + $m-group-nav-arrow-padding *
        2;
    }

    &.is-active,
    &:hover,
    &:focus {
      background: $m-group-nav-link-hover-background;
      color: $m-group-nav-link-hover-color;
      text-decoration: none;
      font-weight: $m-group-nav-link-hover-font-weight;
    }

    &.is-unavailable {
      opacity: 0.5;
    }

    &--dropdown-toggle {
      border: 0; // override default button
      background: none; // override default button
      font-size: inherit; // override default button

      &.is-active,
      &:hover,
      &:focus {
        background: none;
      }
    }

    .m-group-nav__list--horizontal & {
      padding-left: $m-group-nav-link-padding;
      padding-right: $m-group-nav-link-padding;
    }

    .m-group-nav__list--tabs & {
      padding: $m-group-nav-tabs-link-padding;
      border-bottom: $m-group-nav-tabs-link-border-width solid
        var(--form-control-border-color);
      font-size: 1rem;

      @media screen and (max-width: $grid-float-breakpoint-max) {
        @include text-ellipsis();
        max-width: 40ch; // TODO: Think about it
        font-size: 0.875rem;
      }

      &.m-group-nav__link {
        color: var(--color-body-text);
        background-color: unset;
      }

      &.m-group-nav__link:focus-visible,
      &.m-group-nav__link.is-active {
        // TODO: Solve these conflicts.
        // Double selector for specificty reasons. There are conflicting styles in _menu.scss.liquid comes from settings. Decide what to do with these conflicts.
        color: var(--color-primary);
        background-color: unset;
      }

      &:focus-visible {
        outline: 2px solid var(--color-primary);
      }

      &.is-active {
        border-bottom-color: var(--color-primary);
      }

      // Hover styles are applied only when the device supports hovering
      @media (hover: hover) {
        &.m-group-nav__link:hover {
          color: var(--color-primary);
          background-color: unset;
        }
      }
    }
  }

  &__list-item {
    .m-group-nav__list--horizontal & {
      flex: 1 1 auto;
    }

    .m-group-nav__list--tabs & {
      flex: 0 0 auto;
    }
  }

  &__link-image {
    display: block;
    margin: 0 auto 0.5em;
    border-radius: $border-radius-base;
  }

  &--not-mobile {
    @media screen and (max-width: $screen-xs-max) {
      display: none;
    }
  }

  &--sticky {
    max-width: 100%;
    z-index: $m-group-nav-sticky-zindex;
    position: sticky;
    top: 0;

    &.m-group-nav--aside {
      .l-navbar-affixed & {
        // Add 10px as a safe top gap value from sticky header to sticky navigation
        padding-block-start: 10px;
      }
    }
  }

  &--dropdown {
    position: relative;
  }

  &--aside {
    .m-group-nav__link {
      border-top: $m-group-nav-aside-link-border;
    }
  }

  &--tabs {
    position: relative;
    overflow-x: hidden;
    display: flex;

    @media screen and (min-width: $grid-float-breakpoint) {
      justify-content: center;
    }

    // Extend the tabs bottom border if tabs are smaller than screen width
    &:after {
      content: "";
      display: block;
      flex: 1 1 auto;
      align-self: flex-end;
      height: $m-group-nav-tabs-link-border-width;
      background-color: var(--form-control-border-color);
    }

    @media screen and (min-width: $grid-float-breakpoint) {
      &:after {
        display: none;
      }
    }
  }
}
