
$default-spacing: 20px;

.text-centered {
  text-align: center;
}


.multi-column {
  display: flex;
  flex-flow: row wrap;

  & > * {
    flex-grow: 1;
    width:            0px;

    margin-bottom:    0px;
  }

  &.spaced {
    margin-top:     0px;
    margin-bottom:  $default-spacing;
  }

  &.separated {
    margin-top:       -$default-spacing;
    margin-left:      -$default-spacing;

    &.spaced {
      margin-top:     0px;
      margin-bottom:  $default-spacing;
    }

    & > * {
      margin-top:       $default-spacing;
      margin-left:      $default-spacing;
    }
  }

  &.no-stretch {
    justify-content: flex-start;
  }

}

form.vertical {
  .field {
    .inputs {
      margin: $default_spacing / 2 0;
    }
  }
}

form.horizontal {
  .field {

    margin-bottom: $default-spacing / 2;
  }
}

.l-three-columns {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.l-column {
  width: 31%;
}
@include phone {
  .l-column {
    width: 100%;
  }
}


@media screen and (max-width: $grid-float-breakpoint-max) {
  .multi-column:not(.mobile) {
    flex-direction: column;
  }
  .multi-column:not(.mobile) > * {
    width: 100%;
    margin-bottom: 10px;
  }

}


