.m-indicators {
  line-height: 1;

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li, // same as .m-indicators__list-item (required by Splide pagination)
  &__list-item {
    display: inline-block;

    &:not(:first-child) {
      margin-left: 6px;
    }
  }

  &__arrow {
    @extend %button-unstyled;
  }

  &__btn {
    @extend %button-unstyled;
    position: relative;
    width: 14px;
    height: 14px;
    border: 2px solid #fff;
    border-radius: 50%;

    &:focus,
    &:hover,
    &.is-active {
      &:before {
        background-color: #fff;
      }
    }

    &:before {
      content: "";
      @include position(absolute, 50% null null 50%);
      margin-top: -3px;
      margin-left: -3px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
    }
  }
}
