$m-show-more-text-number-of-lines: 2 !default;
$m-show-more-text-max-height: $m-show-more-text-number-of-lines *
  $line-height-base * 1em;

.m-show-more {
  &__state {
    @include visually-hidden();
    visibility: hidden;

    &:checked {
      & ~ .m-show-more__text {
        display: block;
        max-height: none;
      }
    }
  }
  // Alternative class for Preact (storing state in useState, not in checkbox input)
  &.is-expanded {
    & .m-show-more__text {
      display: block;
      max-height: none;
    }
  }

  &__text {
    @include line-clamp($m-show-more-text-number-of-lines);
    // 1. Fix for safari browsers. More info here: https://restaumatic.atlassian.net/browse/RS-4395?focusedCommentId=13421
    line-height: $line-height-base; // [1]
    max-height: $m-show-more-text-max-height; // [1]
  }

  &__trigger {
    display: none;
    margin: 0 0 0 auto;
    font-size: inherit;
    text-align: end;
    text-decoration: underline;
    cursor: pointer;

    .m-show-more__state:focus-visible ~ & {
      color: $link-color;
    }

    // Trigger element must have "data-read-more" & "data-read-less" attributes with content or sign for proper trigger content appearance
    .m-show-more__state ~ &::before {
      content: attr(data-read-more);
    }
    .m-show-more__state:checked ~ &::before {
      content: attr(data-read-less);
    }
  }

  &.is-active {
    .m-show-more__state {
      visibility: visible;
    }

    .m-show-more__trigger {
      display: block;
    }
  }
}
