$m-loyalty-stamp-bg: rgba(#000, 0.05) !default;

.m-loyalty-modal {
  text-align: center;

  &__content {
    padding: 0 map-get($spacers, 6);
  }

  &__title {
    margin-top: 0;
    margin-bottom: map-get($spacers, 5);
  }

  &__success-icon {
    font-size: 50px;
  }

  &__success-image {
    width: 50px;
    height: auto;
    margin: map-get($spacers, 4) auto map-get($spacers, 5);
  }

  // Reset default bg & border values
  .modal-header,
  .modal-footer {
    border: none;
    background: none;
  }

  .m-hr--slim {
    border: none;
  }

  .m-hr--dashed {
    margin-top: map-get($spacers, 6);
    margin-bottom: map-get($spacers, 6);
    border-top-width: 2px;
    border-color: $separator-color;
  }
}

.m-loyalty-stamp {
  display: inline-block;
  width: 45px;
  height: 45px;
  background: $m-loyalty-stamp-bg;
  text-align: center;
  vertical-align: middle;
  border-radius: 5px;
  margin: 2px;

  @media screen and (min-width: $screen-md-min) {
    width: 50px;
    height: 50px;
  }

  &__image {
    opacity: 1;
    max-width: 100%;
  }

  &.is-empty &__image {
    opacity: 0;
  }
}

// Old styles from components/loyalty.scss
// TODO: refactor this (reffer to current loyalty-modal styles above to have one implementation for `stamp` for example).
.media .loyalty {
  color: white;
  position: absolute;
  text-align: center;
  font-weight: bold;
  z-index: 1;
  text-shadow: 0 1px black;
  top: -2px;
  right: -22px;
  width: 50px;

  & > img {
    position: absolute;
    display: block;
    z-index: -1;
    top: -17px;
    background: white;
    border-radius: 500px;
  }
}

div.loyalty-widget {
  padding: 10px;
  border: 2px dashed #f3f3f3;
  text-align: center;
  margin-bottom: 15px;
  background: white;
  color: #333;
}
.loyalty-widget h4 {
  text-align: center;
  color: inherit;
  margin-bottom: 15px;
}
.loyalty-widget .stamp {
  display: inline-block;
  width: 45px;
  height: 45px;
  background: #f3f3f3;
  text-align: center;
  vertical-align: middle;
  border: 2px dashed #ececec;
}
.loyalty-widget .stamp img {
  width: 100%;
}

.loyalty-widget .stamp.empty img {
  opacity: 0.2;
}
