
.visible-closed {
  display: none;
}

.restaurant-closed .hidden-closed {
  display: none;
}

.restaurant-closed .visible-closed {
  display: inherit;
}

.restaurant-closed .add-button {
  opacity: 0.6;
}


.add-button + .dropdown-menu {
  left: auto;
  text-align: left;
  right: 0;
}

.size-info {
  text-align: center;
}

.menu .actions{
  //text-align: center;
  //position: relative;
}


.l-menu-promotions {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.l-menu-promotion-item {
  width: 31%;
}

@include phone {
  .l-menu-promotion-item {
    width: 100%;
  }
}
