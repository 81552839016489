@import "functions-bourbon";

// Ensure safe (readable) text color.
//
// Based on ideas from:
// - http://blog.benjamincharity.com/generate-a-safe-text-color-with-sass
// - https://pressupinc.com/blog/2014/06/preserving-readability-variable-text-background-colors-sass/
//
// TODO: Gather all such functions here and include them as a shared partial in theme.scss.liquid when needed, e.g. for Minimax or Sensei themes (https://trello.com/c/9HXD6wuv).
$hue-bound-bottom: 30 !default;
$hue-bound-top: 240 !default;
$brightness-diff-bound: 55% !default;

@function checkColorDangerZone($color) {
  @if (lightness($color) < 60 and (hue($color) < $hue-bound-bottom or hue($color) > $hue-bound-top)) {
   @return lighten($color, 60%);
  }

  @if (lightness($color) > 80) {
    @return darken($color, 75%);
  }

  @if (lightness($color) > 70) {
    @return darken($color, 60%);
  }

  @if (lightness($color) > 60) {
    @return darken($color, 50%);
  }
  
  @if (lightness($color) > 40) {
    @return darken($color, 40%);
  }

  @if (lightness($color) > 30) {
    @return darken($color, 50%);
  }

  @else {
    @return lighten($color, 75%);
  }
}

@function ensureSafeColor($color, $background-color: null, $amount: $brightness-diff-bound) {
  @if $background-color == null or $background-color == transparent {
    @return $color;
  }

  // Checking text lightness difference from accent background
  $diff: lightness($color) - lightness($background-color);

  // If low brightness difference
  @if abs($diff) < $amount {
    @return checkColorDangerZone($background-color);
  }

  // High enough contrast; @return original color
  @else {
    @return $color;
  }
}

@function isDarkColor($color: $body-bg, $lightness-bound: 50) {
  @return if(lightness($color) > $lightness-bound, false, true);
}

@function blackOrWhite($color) {
  @return if(isDarkColor($color), #fff, #000);
}

// Makes a light color darker or a dark color lighter depending on color lightness.
// @param $color [Sass::Script::Value::Color]
// @param $amount [Sass::Script::Value::Number] The amount between `0%` and `100%`
@function distinguishColor($color, $amount) {
  @return if(isDarkColor($color), lighten($color, $amount), darken($color, $amount));
}

// Makes a light color lighter or a dark color darker depending on color lightness.
// @param $color [Sass::Script::Value::Color]
// @param $amount [Sass::Script::Value::Number] The amount between `0%` and `100%`
@function strengthenColor($color, $amount) {
  @return if(isDarkColor($color), darken($color, $amount), lighten($color, $amount));
}

// URL-encode color SASS function/convert color to hex SASS function
// source: https://gist.github.com/certainlyakey/e9c0d8f5c87ff47e3d5b
// does not work with colors containing alpha
@function encodecolor($string) {
	@if type-of($string) == 'color' {
        $hex: str-slice(ie-hex-str($string), 4);
        $string:unquote("#{$hex}");
    }
    $string: '%23' + $string;
	@return $string;
}

// Convert a px based font-size to rem.
@function toRem($size, $base: 16) {
  @return $size / $base * 1rem;
}
