$m-checkbox-size: 20px, 20px !default; // width, height
$m-checkbox-checked-color: $brand-primary !default;
$m-checkbox-border: $input-border-shorthand !default;
$m-checkbox-bg-color: $input-bg !default;
$m-checkbox-font-weight: inherit !default;

.m-checkbox {
  padding-left: nth($m-checkbox-size, 1) + ceil(nth($m-checkbox-size, 1) / 2);
  cursor: pointer;
  font-weight: $m-checkbox-font-weight;

  &__icon-state {
    position: relative;
    display: inline-block;
    width: nth($m-checkbox-size, 1);
    height: nth($m-checkbox-size, 2);
    margin-right: ceil(nth($m-checkbox-size, 1) / 2);
    margin-left: -(nth($m-checkbox-size, 1) + ceil(nth($m-checkbox-size, 1) / 2));
    vertical-align: middle;
    border: $m-radio-border;
    background-color: $m-radio-bg-color;
  }

  &__input {
    position: absolute;
    opacity: 0; // hide native checkbox

    &:focus {
      + .m-checkbox__icon-state {
        border-color: $m-checkbox-checked-color;
      }
    }

    &:checked {
      + .m-checkbox__icon-state:after {
        @include position(absolute, 50% null null 50%);
        display: block;
        margin-top: ceil(ceil(nth($m-checkbox-size, 2) / 1.2) / -2) - 1;
        margin-left: ceil(ceil(nth($m-checkbox-size, 1) / 2.5) / -2);
        width: ceil(nth($m-checkbox-size, 1) / 2.5);
        height: ceil(nth($m-checkbox-size, 2) / 1.5);
        border: solid $m-checkbox-checked-color;
        border-width: 0 ceil(nth($m-checkbox-size, 1) / 6.7) ceil(nth($m-checkbox-size, 1) / 6.7) 0;
        content: " ";
        transform: rotate(45deg);
        transform-origin: 50% 50%;
      }
    }
  }

  &.is-disabled {
    cursor: $cursor-disabled;
    opacity: $inactive-opacity;
  }

  &--center {
    padding-left: 0;

    .m-checkbox__icon-state {
      margin-right: auto;
      margin-left: auto;
    }
  }

  // 1. Hardcoded value similar to `creator.scss` (TODO: consider refactor depending on final design).
  &--option {
    display: inline-flex;
    margin-right: 5px;
    margin-bottom: 5px;
    min-height: 50px;
    align-items: center;
    padding: 4px 15px 4px (nth($m-radio-size, 1) + ceil(nth($m-radio-size, 1) / 2) + 15);
    border-radius: $input-border-radius;
    border: 1px solid #d8d8d8; // [1]
    background-color: #fafafa; // [1]

    .l-mobile-app--ios & {
      color: $text-color;
    }
  }
}

// Adjust Bootstrap 3 styles - START
// @deprecated (use `m-radio--inline` instead)
.checkbox-inline,
.checkbox {
  label.m-checkbox {
    padding-left: nth($m-checkbox-size, 1) + ceil(nth($m-checkbox-size, 1) / 2);
    font-weight: $m-checkbox-font-weight;
  }
}

.checkbox-inline {
  margin-right: 15px;
  padding-left: 0;

  + .checkbox-inline {
    margin-left: 0; // reset Bootstrap margin
  }
}
// Adjust Bootstrap 3 styles - END
