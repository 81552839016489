.m-copy-btn {
  position: relative;
  overflow: hidden;
  font-size: $font-size-small;

  &__content {
    transition-property: opacity, transform, visibility;
    transition-duration: $base-duration;
    transition-timing-function: linear;

    &--copied {
      position: absolute;
      left: 0;
      right: 0;
      opacity: 0;
      visibility: hidden;
      transform: translateX(50%);
    }
  }
  &.is-copied {
    .m-copy-btn__content--init {
      opacity: 0;
      visibility: hidden;
    }

    .m-copy-btn__content--copied {
      opacity: 1;
      visibility: visible;
      transform: translateX(0);
    }
  }

  // TODO: Change styling :focus to :focus-visible globally
  &:focus {
    outline-color: transparent;
    outline-width: 0;
  }

  &:focus-visible {
    outline-color: revert;
    outline-width: revert;
  }
}
