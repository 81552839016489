$m-restaurant-hours-row-padding: 6px !default;

.m-restaurant-hours {
  // Variant where parent element sets the layout alignment, e.g. center or right (left is default).
  &--parent-align {}

  &__item {
    display: table-row;
    padding-top: $m-restaurant-hours-row-padding;
    padding-bottom: $m-restaurant-hours-row-padding;

    .m-restaurant-hours--parent-align & {
      display: block;
    }

    &--singular {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &__header {
    padding-right: $grid-gutter-width / 2;
  }

  &__header,
  &__data-wrapper {
    display: table-cell;

    .m-restaurant-hours--parent-align & {
      display: inline;
    }
  }

  &__header,
  &__data-wrapper,
  &__data {
    white-space: nowrap;
  }

  &__btn-popover {
    border: 0;
    padding: 0;
    background: transparent;
  }

  &__btn-collapse {
    border: 0;
    padding: 3px 5px;
    background: transparent;
  }

  .icon-ok::before,
  .icon-remove::before,
  .icon-info-sign::before,
  .icon-caret-up::before,
  .icon-caret-down::before {
    vertical-align: baseline;
  }

  &--stretch {
    .m-restaurant-hours__data-wrapper {
      float: right;
      display: flex;
    }

    .m-restaurant-hours__data {
      order: 1;
    }

    .m-restaurant-hours__btn-collapse {
      order: 1;
    }
  }

  &--nowrap {
    .m-restaurant-hours__item {
      white-space: nowrap;
    }
    .m-restaurant-hours__info {
      white-space: normal;
    }
  }
}
