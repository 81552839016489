.m-featured-items {
  $gutter: floor($grid-gutter-width / 2);

  &__list {
    margin: $gutter / -1;
    padding-left: 0;
    list-style: none;
    text-align: center;

    &:not(.splide__list) {
      display: block;
    }
  }

  &__list-item {
    display: block;
    padding: $gutter;
  }

  &__image {
    margin-right: auto;
    margin-left: auto;

    .col-sm-auto & {
      @media (min-width: $screen-sm-min) {
        max-width: 40vw;
      }
    }
  }
}
