$m-confirmation-icon-color: inherit !default; // color name or hex format is required

.m-confirmation {
  &__list {
    @include make-row();
    margin-bottom: 0;
    padding-left: 0;
    list-style-position: inside;
  }

  &__list-item {
    @include make-sm-column(6);
    margin-bottom: ($line-height-computed / 2);
  }

  &__button {
    cursor: pointer;
  }

  &__transition-icon {
    display: none;
    margin-top: 4px;
    color: $m-confirmation-icon-color;

    @media screen and (min-width: $screen-sm-min) {
      display: inline-block;
      float: right;
      // override example:
      //background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cg%20fill%3D%22#{str-replace("#{$m-confirmation-icon-color}", "#", "%23")}%22%3E%3Cpath%20d%3D%22M16%207.8C16%207.8%2016%207.8%2016%207.8%2016%207.8%2016%207.7%2016%207.7%2016%207.6%2016%207.6%2016%207.5%2016%207.5%2016%207.5%2016%207.5%2015.9%207.5%2015.9%207.5%2015.9%207.4%2015.9%207.4%2015.9%207.4%2015.9%207.4L7.6%200.1C7.4%200%207.2%200%207.1%200.1%207%200.3%207%200.5%207.1%200.6L14.8%207.3%200.3%207.3C0.1%207.3%200%207.5%200%207.7%200%207.9%200.1%208%200.3%208L14.8%208%207.1%2014.8C7%2014.9%207%2015.1%207.1%2015.2%207.1%2015.3%207.2%2015.3%207.3%2015.3%207.4%2015.3%207.5%2015.3%207.6%2015.3L15.9%207.9C15.9%207.9%2015.9%207.9%2015.9%207.9%2015.9%207.9%2015.9%207.9%2016%207.8L16%207.8Z%22/%3E%3C/g%3E%3C/svg%3E'); // svg is URL-encoded for better cross-browser compatibility
    }
  }

  &__label,
  .control-label {
    display: block;
  }
}
