$m-author-font-size: $font-size-small-unit !default;

.m-author {
  display: flex;
  font-style: normal;
  font-size: $m-author-font-size;

  &__image {
    margin-right: 12px;
  }

  &__info {
    line-height: 1.2;
  }

  &__image-img {
    max-width: 32px;
    border-radius: 50%;
  }

  &__time {
    font-size: 0.6875rem;
  }
}
