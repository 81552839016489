$m-nav-toggle-padding: 0 !default;
$m-nav-toggle-font-size: 25px !default;
$m-nav-toggle-unstyled: true !default;

.m-nav-toggle {
  &__btn {
    @if ($m-nav-toggle-unstyled == true) {
      @extend %button-unstyled;
    }
    padding: if($m-nav-toggle-padding != 0, $m-nav-toggle-padding, null);
    font-size: $m-nav-toggle-font-size;
  }
}
