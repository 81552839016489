.m-customer-survey {
  margin-right: auto;
  margin-left: auto;
  max-width: 768px;

  &__form {
    margin-bottom: $form-group-margin-bottom;
  }

  &__row-item {
    padding: 15px 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    @at-root label.m-customer-survey__row-item {
      cursor: pointer;
    }
  }

  legend {
    border: none;
  }

  fieldset {
    border: none;
  }

  &__section {
    margin: $form-group-margin-bottom 0;
    padding: $form-group-margin-bottom 0;
  }
}
