$m-fulfillment-time-spacing-block: map-get($spacers, 4) !default;
$m-fulfillment-slider-bg-color: var(--modal-bg, #fff) !default;
$m-fulfillment-slider-content-scrollbar-gutter: 6px !default;
$m-fulfillment-slider-arrow-size: 30px !default;
$m-fulfillment-slider-arrow-horizontal-offset: map-get($spacers, 2) !default;

.m-fulfillment-time {
  &__slider {
    display: flex;
    gap: map-get($spacers, 2);
    padding-block-start: $m-fulfillment-time-spacing-block;
    padding-block-end: calc(
      #{$m-fulfillment-time-spacing-block} - #{$m-fulfillment-slider-content-scrollbar-gutter}
    );
    background-color: $m-fulfillment-slider-bg-color;
    z-index: 1;

    @media screen and (min-width: $grid-float-breakpoint) {
      position: relative;
      padding-block-end: $m-fulfillment-time-spacing-block;
      overflow-x: hidden;
    }
  }

  &__list {
    &:only-child {
      margin-block-start: $m-fulfillment-time-spacing-block;
    }
  }

  &__slider-arrow {
    align-self: center;
    background-color: $m-fulfillment-slider-bg-color;

    &--prev {
      padding-inline-end: $m-fulfillment-slider-arrow-horizontal-offset;
    }

    &--next {
      padding-inline-start: $m-fulfillment-slider-arrow-horizontal-offset;
    }

    @media screen and (max-width: $grid-float-breakpoint-max) {
      display: none;

      &.is-visible {
        display: block;
      }
    }

    @media screen and (min-width: $grid-float-breakpoint) {
      position: absolute;
      height: 100%;
      display: flex;
      align-items: center;
      visibility: collapse;
      transition-property: transform, visibility;
      transition-duration: 0.2s;
      transition-timing-function: ease-in-out;

      &.is-visible {
        transform: translate(0);
        visibility: visible;
        z-index: 1;
      }

      &--prev {
        left: 0;
        transform: translate(-100%);
      }

      &--next {
        right: 0;
        transform: translate(100%);
      }
    }
  }

  &__arrow-btn {
    width: $m-fulfillment-slider-arrow-size;
    height: $m-fulfillment-slider-arrow-size;
    font-size: 14px;
    border: 1px solid var(--color-separator);
    border-radius: 50%;
  }

  &__slider-content {
    @include custom-scrollbar(#bfbfbf);
    display: flex;
    flex: 1 1 100%;
    gap: map-get($spacers, 2);
    padding-block-end: $m-fulfillment-slider-content-scrollbar-gutter; // For scrollbar gutter purposes
    overflow-x: auto;

    @media screen and (min-width: $grid-float-breakpoint) {
      scroll-snap-type: x mandatory;
      @include hide-scrollbar();
    }
  }

  &__slider-item,
  &__slider-arrow {
    scroll-snap-align: start;
  }

  &__slider-item {
    flex: 0 0 11ch;

    @media screen and (min-width: $grid-float-breakpoint) {
      scroll-margin-left: calc(
        #{$m-fulfillment-slider-arrow-size} + #{$m-fulfillment-slider-arrow-horizontal-offset}
      );

      .m-fulfillment-time__slider-arrow--prev.is-visible + & {
        margin-inline-start: calc(
          #{$m-fulfillment-slider-arrow-size} + #{$m-fulfillment-slider-arrow-horizontal-offset}
        );
      }
    }
  }

  &__day {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-block: map-get($spacers, 3);
    padding-inline: map-get($spacers, 4);
    border: 2px solid var(--color-separator);
    border-radius: $border-radius-base;
    text-align: start;
    font-family: inherit;
    transition: none;

    &.is-active {
      background-color: color-mix(
        in srgb,
        var(--color-primary) 10%,
        transparent
      );
      border-color: var(--color-primary, $brand-primary);
    }

    &.is-disabled {
      color: var(--text-muted, #{$text-muted});
      opacity: $inactive-opacity;
      border-color: var(--color-separator);
    }
  }
}
