.m-page-subheader {
  @include clearfix;

  &__title {
    @extend .h2;
    margin: 0 0 $line-height-computed;
    font-weight: $font-weight-base;
    text-align: center;
  }
}
