.m-info-boxes {
  @at-root .l-page-header-ls & {
    letter-spacing: $pasja-letter-spacing;
  }

  &__list {
    @include list-unstyled;
    display: flex;
    flex-flow: row wrap;

    @media screen and (min-width: $grid-float-breakpoint) {
      flex-wrap: nowrap;
    }
  }

  &__list-item {
    padding: floor($grid-gutter-height / 2) floor($grid-gutter-width / 2) floor($grid-gutter-height / 2);
    flex: 1 1 170px;
    text-align: center;

    @media screen and (min-width: $grid-float-breakpoint) {
      padding-top: $grid-gutter-height;
      padding-bottom: $grid-gutter-height;

      &:not(:first-child) {
        border-left: 5px solid $brand-secondary; // color defined in theme.scss.liquid
      }
    }
  }
}
