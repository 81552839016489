$m-radio-size: 20px, 20px !default; // width, height
$m-radio-checked-color: $brand-primary !default;
$m-radio-border: $input-border-shorthand !default;
$m-radio-bg-color: $input-bg !default;
$m-radio-font-weight: inherit !default;
$m-radio-button-box-padding: $padding-base-vertical $padding-base-horizontal;
$m-radio-button-icon-state-margin-left: ceil($base-spacing / 6);
$m-radio-button-icon-state-margin-right: ceil($base-spacing / 3);

.m-radio {
  padding-left: nth($m-radio-size, 1) + ceil(nth($m-radio-size, 1) / 2);
  cursor: pointer;
  font-weight: $m-radio-font-weight;

  &__icon-state {
    position: relative;
    display: inline-block;
    width: nth($m-radio-size, 1);
    height: nth($m-radio-size, 2);
    margin-right: ceil(nth($m-radio-size, 1) / 2);
    margin-left: -(nth($m-radio-size, 1) + ceil(nth($m-radio-size, 1) / 2));
    border-radius: 50%;
    vertical-align: middle;
    border: $m-radio-border;
    background-color: $m-radio-bg-color;
  }

  &__label {
    cursor: inherit;
    margin-bottom: 0;
  }

  &__input {
    position: absolute;
    opacity: 0; // hide native radio

    &:focus {
      + .m-radio__icon-state {
        border-color: $m-radio-checked-color;
      }
    }

    &:focus-visible {
      + .m-radio__img-container {
        opacity: 0.5;
      }
    }

    &:checked {
      + .m-radio__icon-state:after {
        @include position(absolute, 50% null null 50%);
        display: block;
        width: ceil(nth($m-radio-size, 1) / 2);
        height: ceil(nth($m-radio-size, 2) / 2);
        border-radius: 50%;
        background-color: $m-radio-checked-color;
        transform: translateX(-50%) translateY(-50%);
        content: " ";
      }
    }
  }

  &--button:focus-within,
  &--block:focus-within {
    border-color: var(--color-primary, $brand-primary);
  }

  &.is-disabled {
    cursor: $cursor-disabled;
    opacity: $inactive-opacity;
    filter: grayscale(100%);
  }

  &--center {
    padding-left: 0;

    .m-radio__icon-state {
      margin-right: auto;
      margin-left: auto;
    }
  }

  // 1. Hardcoded value similar to `creator.scss` (TODO: consider refactor depending on final design).
  &--option {
    display: inline-flex;
    margin-right: 5px;
    margin-bottom: 5px;
    min-height: 50px;
    align-items: center;
    padding: 4px 15px 4px
      (nth($m-radio-size, 1) + ceil(nth($m-radio-size, 1) / 2) + 15);
    border-radius: $input-border-radius;
    border: 1px solid #d8d8d8; // [1]
    background-color: #fafafa; // [1]

    .l-mobile-app--ios & {
      color: $text-color;
    }
  }

  &--button {
    padding: 0; // reset default radio padding
    margin-bottom: ceil($base-spacing / 4);
    border: $input-border-shorthand;
    border-radius: $input-border-radius;
    background-color: $input-bg;
    box-shadow: $input-box-shadow;

    &.is-active {
      border-color: $brand-primary;
    }

    .m_radio__body {
      display: flex;
      align-items: center;
      padding: $m-radio-button-box-padding;
      min-height: $input-height-base;
    }

    .m-radio__icon-state {
      margin-left: $m-radio-button-icon-state-margin-left;
    }

    .m-radio__img-container,
    .m-radio__icon-state {
      margin-right: $m-radio-button-icon-state-margin-right;
    }
  }

  &__children {
    padding: $m-radio-button-box-padding;
    margin-block-start: ceil($base-spacing / 4);
    height: 0;
    overflow-y: clip;
    transition-property: height, margin-block, padding-block;
    transition-duration: $base-duration;

    .m-radio:not(.is-active) &,
    &:empty {
      margin-block: revert;
      padding-block: revert;
    }

    .m-radio.is-active & {
      height: auto;
    }

    // TODO: Better way to do this is probably make new element as separate param (e.g. description) and put it directly below m-radio__label
    &--align-to-label {
      .m-radio--button & {
        margin-inline-start: calc(
          #{nth($m-radio-size, 1)} + #{$m-radio-button-icon-state-margin-left} +
            #{$m-radio-button-icon-state-margin-right}
        );
        margin-block-start: 0;
        padding-block-start: 0;
      }
    }

    .form-group {
      // reduce form group margin a little bit
      margin-bottom: ceil($base-spacing / 2);
    }
  }

  &__block-container {
    display: flex;
    flex-wrap: wrap;
    gap: ceil($base-spacing / 4);
    cursor: auto;
  }

  &--block {
    flex: 1 1 120px;
    min-width: 120px;
    padding: 0;
    border: $input-border-shorthand;
    border-radius: $input-border-radius;
    background-color: $input-bg;
    box-shadow: $input-box-shadow;

    &.is-active {
      border-color: $brand-primary;
    }

    .m_radio__body {
      padding: $padding-base-vertical $padding-base-horizontal;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .m-radio__img-container {
      margin: ceil($base-spacing / 4) 0 ceil($base-spacing / 3) 0;
    }

    .m-radio__label {
      margin-bottom: 0;
      text-align: center;
      line-height: 110%;
      font-size: $font-size-small;
    }
  }

  // Special variant for custom extension with icons or any other type of presentation (e.g. star ratings `_star_rating.liquid`).
  // There are no markup requirements except of the `.m-radio--custom .m-radio__input` which prevents `js/utils/Form.js` from transforming regular `radio` for custom-styled `radio`.
  &--custom {
    margin: 0;
    padding: 0;
  }

  &__img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 30px;
  }

  &__img {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }
}

// Adjust Bootstrap 3 styles - START
// @deprecated (use `m-radio--inline` instead)
.radio-inline,
.radio {
  .m-radio {
    padding-left: nth($m-radio-size, 1) + ceil(nth($m-radio-size, 1) / 2);
    font-weight: $m-radio-font-weight;

    &__icon-state {
      margin-right: ceil(nth($m-radio-size, 1) / 2);
      margin-left: -(nth($m-radio-size, 1) + ceil(nth($m-radio-size, 1) / 2));
    }
  }
}

.radio-inline {
  margin-right: 15px;
  padding-left: 0;

  + .radio-inline {
    margin-left: 0; // reset Bootstrap margin
  }
}
// Adjust Bootstrap 3 styles - END
