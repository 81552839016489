.m-hr {
  @extend hr;

  &--slim {
    margin-top: ($line-height-computed / 2);
    margin-bottom: ($line-height-computed / 2);
  }

  &--dashed {
    border-style: dashed;
  }
}
