.m-scatter-gallery {
  &__list {
    @include list-unstyled;
    position: relative;
    margin: 0;
    text-align: center;

    @media screen and (min-width: $screen-xs-min) {
      height: 450px;
    }
  }

  &__list-item {
    display: inline-block;
    margin-bottom: 4px;
    vertical-align: top;
    width: 45%;

    @media screen and (min-width: $screen-xs-min) {
      position: absolute;
      width: auto;

      &:nth-child(1) {
        top: 0;
        left: 0;
      }

      &:nth-child(2) {
        top: 30px;
        right: 0;
      }

      &:nth-child(3) {
        right: 240px;
        bottom: 0;
      }

      &:nth-child(4) {
        bottom: 75px;
        left: 260px;
      }
    }
  }

  &__item-link {
    position: relative;
    display: block;
    background-color: $body-bg;
    border: 0 solid #fff;
    transition: border-width $base-duration, box-shadow $base-duration;
    transform: translateZ(0);

    &:focus,
    &:hover {
      @include pasja-box-shadow();
      z-index: $zindex-ui;
      border: 17px solid #fff;

      .m-scatter-gallery__thumbnail-img {
        @include pasja-blend-mode(true);
      }

      &:after {
        @include position(absolute, 50% null null 50%);
        @include icon();
        margin-top: -30px;
        margin-left: -30px;
        padding-top: 15px;
        width: 60px;
        height: 60px;
        font-size: 30px;
        color: #fff;
        text-align: center;
        //background-color: $brand-primary; // defined in theme.scss.liquid
        border-radius: 50%;
        content: '\e80a';
      }
    }
  }

  &__thumbnail-img {
    @include pasja-blend-mode();
    transform: translateZ(0);
  }
}
