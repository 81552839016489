.m-featured {
  overflow: hidden;

  &__list {
    @include list-unstyled;
    @include clearfix;
    margin: 0;
  }

  &__list-item {
    position: relative;
    float: left;
    min-height: 1px;
    width: 100vw; // prevent "Flash of Unstyled Content" effect
    overflow: hidden;
  }

  &__item-img {
    @include position(absolute, 0 0 0 0);
    background-position: 50% 50%;
    background-size: cover;

    &:before {
      @include position(absolute, 0 0 0 0);
      //background-color: $brand-complementary; // defined in theme.scss.liqud
      opacity: 0.85;
      content: " ";
    }
  }

  &__item-content {
    position: relative;
  }

  &__item {
    margin-right: auto;
    margin-left: auto;
    position: relative;
    max-width: 600px;
    padding: 150px 50px;
    text-align: center;

    @media screen and (min-width: $grid-float-breakpoint) {
      padding-right: 100px;
      padding-left: 100px;
    }
  }

  &__title {
    margin: 0 0 29px;
    font-size: 2.25rem;
    color: #fff;
    text-transform: uppercase;

    @at-root .l-page-header-ls & {
      letter-spacing: $pasja-letter-spacing;
    }

    &:after {
      display: block;
      width: 60px;
      height: 5px;
      margin: 29px auto 0;
      background-color: #fff;
      content: " ";
    }
  }

  &__description {
    margin-bottom: 29px;
    color: #ccc;
  }

  &__arrows {
    @include container-fixed();
    @include position(absolute, 50% 0 null 0);
    margin-top: -35px;
    z-index: $zindex-ui;
    max-width: 620px;
    color: #fff;

    .m-indicators {
      &__arrows {
        display: flex;
        justify-content: space-between;
      }

      &__arrow {
        padding: 20px 10px;
      }
    }
  }
}
