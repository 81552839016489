.m-restaurants-overview {
  width: 100%;

  &__cols {
    margin-right: floor($grid-gutter-width / -2);
    margin-left: floor($grid-gutter-width / -2);
    display: flex;
    flex-wrap: wrap;

    &--grid {
      &.m-restaurants-overview__cols--space-control {
        margin-bottom: map-get($spacers, 7) / -1;
      }
    }
  }

  &__col {
    margin-bottom: map-get($spacers, 6);
    padding-right: floor($grid-gutter-width / 2);
    padding-left: floor($grid-gutter-width / 2);
    flex: 1 1 300px;

    @media screen and (min-width: $screen-lg-min) {
      flex-basis: (100% / 4);

      &--logo {
        max-width: 25%;
        flex-basis: auto;
      }
    }

    .m-restaurants-overview__cols--grid & {
      margin-bottom: map-get($spacers, 7);
    }
  }
}
