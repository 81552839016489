// Global variables
// Important: use `base/variable-overrides` if you want to override vendor defaults.

// Base
$theme-name: null !default;
$base-duration: 0.3s !default; // transitions, animations
$hover-opacity: 0.7 !default; // opacity for hover and focus states
$muted-opacity: 0.75 !default; // opacity for muted text
$inactive-opacity: 0.65 !default; // opacity for inactive/disabled states (be aware of minimum WCAG contrast level and make sure important content is accessible)
$outline-default: 1px dashed rgba(#ccc, 0.5) !default; // default outline (accessibility)

// Colors
$separator-color: transparent !default; // color of the separator
$text-strong-color: inherit !default; // strong text color

// Layout
$screen-xlg-min: 1366px !default; // extra large screen
$screen-lg-max: ($screen-xlg-min - 1) !default; // extra large screen max
$grid-gutter-height: $grid-gutter-width !default; // vertical padding between columns
$base-spacing: $grid-gutter-height !default; // base space between components
$base-spacing-large: $base-spacing * 2 !default; // base space between components for large viewports

// Z-index master list
// Z-index values form Bootstrap (listed here for reference, defined in `bootstrap/_variables.scss`).
// $zindex-navbar: 1000 !default;
// $zindex-dropdown: 1000 !default;
// $zindex-popover: 1060 !default;
// $zindex-tooltip: 1070 !default;
// $zindex-navbar-fixed: 1030 !default;
// $zindex-modal-background: 1040 !default;
// $zindex-modal: 1050 !default;

// Custom z-index values
$zindex-ui: 900 !default; // default `z-index` for UI elements
$zindex-cookie-warning: 950 !default;
$zindex-floating-action-btn: 1020 !default;
$zindex-smart-app-banner: 1035 !default;
$zindex-notification: 1037 !default;
$zindex-mobile-navigation-background: 1040 !default;
$zindex-mobile-navigation: 1048 !default;

// Spacing
$spacers: (
  0: 0,
  1: .25rem, // ~4px
  2: .5rem, // ~8px
  3: .75rem, // ~12px
  4: 1rem, // 16px
  5: 1.5rem, // ~24px
  6: 2rem, // ~32px
  7: 3rem, // ~48px
  8: 4rem, // ~64px
  9: 6rem, // ~96px
  10: 8rem, // ~128px
  11: 12rem // ~192px
);

// Breakpoints
$grid-breakpoints: (
  0: 0,
  xs: $screen-xs-min,
  sm: $screen-sm-min,
  md: $screen-md-min,
  lg: $screen-lg-min
);

// Typography
$font-weight-base: normal !default; // base font weight
$font-weight-bold: bold !default; // bold font weight

// Usage of these variables is not recommended. Better approach is to use `rem` values and scale font sizes using root element value.
$headings-size-breakpoint: null !default;
$font-size-h1-gfb: null !default;
$font-size-h2-gfb: null !default;
$font-size-h3-gfb: null !default;
$font-size-h4-gfb: null !default;
$font-size-h5-gfb: null !default;
$font-size-h6-gfb: null !default;

// Form
$input-box-shadow: inset 0 1px 1px rgba(#000, 0.075) !default;
$input-border-shorthand: 1px solid $input-border !default;
$form-centered-max-width: 640px !default;

// Size of a form-control inputs, introduced as a workaround for legacy themes, which use small base font sizes (iOS zooms the page to show a larger field if its font size is less than 16px).
// The intention behing this variable is to provide a way to prevent a browsers from zooming the page on input focus (which can impact the UX), without breaking the accessibility with solutions like `maximum-scale=1.0` etc.
$form-control-font-size-base: null !default; // It should be at least 16px.
$form-control-input-height-base: if($form-control-font-size-base != null, (floor(($form-control-font-size-base * $line-height-base)) + ($padding-base-vertical * 2) + 2), null) !default; // Adjust input height in case of custom font-size (adapted from Bootstrap `$input-height-base`).

// Buttons
$btn-text-transform: none !default;
$btn-box-shadow: null !default;

// btn
$btn-padding-base-vertical: $padding-base-vertical !default;
$btn-padding-base-horizontal: $padding-base-horizontal !default;
$btn-font-size-base: $font-size-base !default;
$btn-line-height-base: $line-height-base !default;

// btn-sm
$btn-padding-small-vertical: $padding-small-vertical !default;
$btn-padding-small-horizontal: $padding-small-horizontal, !default;
$btn-font-size-small: $font-size-small !default;
$btn-line-height-small: $line-height-small !default;
$btn-border-radius-small: $btn-border-radius-small !default;

// btn-lg
$btn-padding-large-vertical: $padding-large-vertical !default;
$btn-padding-large-horizontal: $padding-large-horizontal !default;
$btn-font-size-large: $font-size-large !default;
$btn-line-height-large: $line-height-large !default;

// Dropdown menus
$dropdown-font-size: $font-size-base !default; // Bootstrap doesn't provide variable for it, so we define default value here.
$dropdown-link-padding: 7px 20px !default; // The same as above.
$dropdown-link-padding-mobile: 10px 20px !default; // The same as above but for mobile viewport.

// Wells
$well-text-color: if(lightness($well-bg) > 50%, darken($well-bg, 50%), lighten($well-bg, 50%));
$well-title-color: if(lightness($well-bg) > 50%, darken($well-bg, 60%), lighten($well-bg, 60%));

// Labels
$labels-padding: null !default;
$labels-text-transform: null !default;
$labels-font-weight: 700 !default;
$labels-border-radius: null !default;

// Legend
$legend-font-size: 100% !default;
$legend-font-weight: 700 !default;
$legend-border: none !default;
$legend-margin-bottom: 5px !default;

// Close
$close-font-size: null !default;

// Modals
$modal-title-text-transform: null !default;
$modal-dialog-border-radius: $border-radius-large !default;


// TODO: Think about where this code should be defined https://restaumatic.atlassian.net/browse/B2C-105
// CSS Variables
:root {
  --base-duration: #{$base-duration};
  --form-wrapper-max-width: 750px;
 }
