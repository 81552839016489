$creator-item-border-color: #d8d8d8 !default;
$creator-item-bg-color: #f5f5f5 !default;
$creator-item-selected-bg-color: #fffde9 !default;

.topping-row {
  margin-right: 0;
  margin-left: 0;
}

.topping-group-name {
  @media (min-width: $screen-md-min) {
    margin-top: 0;
  }
}

.topping {
  @extend %button-unstyled;
  padding: 5px;
  font-size: 14px;
  font-family: inherit;
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 50px;
  align-items: center;
  text-align: left;
  line-height: 1.3;

  .is-selected & {
    background: $creator-item-selected-bg-color;
  }

  &:focus {
    @include tab-focus();
  }

  &__icon {
    display: inline-block;
    flex: 0 0 40px;
    width: 40px;
    height: 40px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 10px; // New margin (increased from 0 on desktop and 5px on mobile), but...

    /* Do not apply it in the old "grid" type modifier element */
    .topping & {
      margin-right: 5px;

      @media screen and (min-width: $screen-xs-min) {
        margin-right: 0;
      }
    }
  }

  &__count {
    display: inline-flex;
    flex: 0 1 auto;
    color: #d10;
    position: absolute;
    top: 0;
    left: 0;
    background: white;
    border-radius: 50%;
    width: 1.7em;
    height: 1.7em;
    align-items: center;
    justify-content: center;
    border: 1px solid $creator-item-border-color;
    transition: opacity $base-duration;
    opacity: 1;
    visibility: visible;

    &:empty {
      opacity: 0;
      visibility: hidden;
    }
  }

  &__name {
    @include text-break();
    display: inline-block;
    flex: 1 0;
    white-space: normal;
    text-decoration: none;

    &:not(:last-child) {
      margin-right: 2px;
    }

    .is-disabled & {
      text-decoration: line-through;
    }

    // TODO do we want to have line-through in new product card?
    .is-disabled-new-product-card & {
      text-decoration: none;
    }

    @media screen and (max-width: $screen-xs-min - 1) {
      word-wrap: break-word;
      hyphens: auto;
    }
  }

  &__price {
    white-space: nowrap;
  }

  .l-mobile-app--ios & {
    color: $text-color;
  }
}

// Topping icon for old creator. Use `topping__icon` in menu v2.
.topping-icon {
  display: inline-block;
  flex: 0 0 40px;

  background-repeat: no-repeat;
  background-position: 0 0;
  width: 40px;
  height: 40px;

  .is-selected & {
    background-position: 0px -40px;
  }
}

.topping-item {
  margin-left: -1px;
  margin-top: -1px;
  border: 1px solid $creator-item-border-color;
  background: $creator-item-bg-color;
  padding: 0;
}

.creator {
  &__errors {
    margin-bottom: 0;
    padding: 5px 15px;
    text-align: left;
  }

  &__half-ornament {
    margin-top: -10px;
    margin-left: auto;
    width: 30px;
    height: 60px;
    overflow: hidden;

    &--v2 {
      margin-top: 20px;
    }

    &--2nd {
      img {
        position: relative;
        left: -30px;
      }
    }
  }

  &__half-ornament-img {
    max-width: none; // reset img-responsive
    height: 60px;
  }

  &__img {
    max-height: 40vh;
    width: 100%;
    object-fit: cover;
  }
}
