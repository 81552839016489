.m-split-pizza-list {
  $split-pizza-part-icon-width: 0.45em;
  $split-pizza-part-icon-height: $split-pizza-part-icon-width * 2;
  margin: 0;
  padding-left: $split-pizza-part-icon-width * 3;
  list-style: none;

  &__item {
    position: relative;
    min-height: $split-pizza-part-icon-height;
    line-height: 1.5;

    &:before {
      position: absolute;
      width: $split-pizza-part-icon-width;
      height: $split-pizza-part-icon-height;
      background-color: currentColor;
      top: 0.35em;
      left: $split-pizza-part-icon-width * -2.5;
      border-bottom-right-radius: $split-pizza-part-icon-width;
      border-top-right-radius: $split-pizza-part-icon-width;
      content: "";
    }

    &:first-child {
      &:before {
        border-radius: $split-pizza-part-icon-width 0 0 $split-pizza-part-icon-width;
      }
    }
  }
}
