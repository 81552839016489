
.facebook-like-box {
    position: fixed;
    top: 50%;
    margin-top: -50px;
    z-index: 999;
    left: -300px;
    transition: left .3s cubic-bezier(0.4,0,.2,1);
}

.facebook-like-box:hover {
    left: 0;
}

.facebook-like-box .content {
    background: white;
}

.facebook-like-box .open-button {
    position: absolute;
    right: -46px;
    top: 0;
    cursor: pointer;
    border: 1px solid rgb(13, 13, 105);
}

@include phone {
  .facebook-like-box {
    display: none;
  }
}
