$m-menu-description-margin: 0 !default;
$m-menu-description-padding: 0 !default;
$m-menu-description-background: none !default;
$m-menu-description-color: inherit !default;
$m-menu-description-text-margin: 0 !default;
$m-menu-description-text-align: center !default;
$m-menu-description-font-size: null !default;

.m-menu-description {
  margin: $m-menu-description-margin;
  padding: $m-menu-description-padding;
  background: $m-menu-description-background;
  color: $m-menu-description-color;
  font-size: $m-menu-description-font-size;

  &__text {
    margin: $m-menu-description-text-margin;
    text-align: $m-menu-description-text-align;
  }
}
