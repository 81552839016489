.m-brand {
  margin: 0;
  order: -1;

  &__area {
    display: flex;
    align-items: center;
    font-size: 1.75rem;
    color: inherit;
    text-transform: uppercase;

    &:hover,
    &:focus {
      color: inherit;
    }
  }

  &__name {
    margin-left: 30px;

    @media screen and (max-width: $grid-float-breakpoint-max) {
      display: none;
    }
  }

  &__logo {
    // Note: limited logo values are overwritten in `.m-slider--fullwidth` variant.
    &--limited {
      max-height: 100px;

      @media screen and (min-width: $grid-float-breakpoint) {
        max-width: 200px;
      }
    }
  }
}
