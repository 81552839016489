.modal-dialog {
  --modal-dialog-margin-block: 0px;
  margin-inline: auto;
  margin-block: var(--modal-dialog-margin-block, 0);

  @media screen and (min-width: $screen-sm-min) {
    --modal-dialog-margin-block: 1.75rem;
  }

  &--expanded {
    @media screen and (min-width: $screen-sm-min) {
      margin: 15px;
      width: auto;
    }

    @media screen and (min-width: $screen-lg-min) {
      margin: 30px; // the same as other modals
    }
  }

  &--with-image {
    @supports (object-fit: cover) {
      $image-space-width: 160px;

      @media (min-width: $screen-sm-min) {
        max-width: 95vw;
        width: $modal-md + $image-space-width;

        .modal-content {
          padding-left: $image-space-width;
        }
      }

      @at-root .modal-dialog__image {
        display: none;

        @media (min-width: $screen-sm-min) {
          display: block;
          position: absolute;
          top: 1px;
          left: 1px;
          bottom: 1px;
          height: 100%; // simple fallback
          height: calc(100% - 2px);
          width: $image-space-width - 1px; // additional space for the border
          object-fit: cover;
          object-position: 0 0;
          border-radius: $modal-dialog-border-radius 0 0
            $modal-dialog-border-radius;
        }
      }
    }
  }

  &--small {
    @media screen and (min-width: $screen-sm-min) {
      max-width: 380px;
      // center the modal and restore left and right spacing (10px margins)
      margin-left: auto;
      margin-right: auto;
      padding-top: 10px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  &__inner-mb {
    margin-bottom: nth($modal-inner-padding, 1);
  }

  // When fading in the modal, animate it to slide down
  .modal.show & {
    transform: none;
  }

  // NOTICE: Only for mobile resolutions for now
  @media screen and (max-width: $screen-xs-max) {
    &--full-height {
      height: 100%;

      .modal-content {
        height: 100%;
        display: flex;
        flex-direction: column;
      }

      .modal-body {
        flex-grow: 1;
        overflow-y: auto;
      }
    }
  }

  &--scrollable {
    height: calc(100% - var(--modal-dialog-margin-block) * 2);

    .modal:has(&) {
      overflow-y: hidden;
    }

    .modal-content {
      display: flex;
      flex-direction: column;
      max-height: 100%;
      overflow: hidden;
    }

    .modal-body {
      @include custom-scrollbar();
      overflow-y: auto;
    }
  }
}

// NOTICE: Here are Safari bugfixes for updating rendered HTML of `position: sticky` elements (will-change: transform + flex-basis: auto helps) - if you change something here, make sure to test it in Safari,
// Example test: add an element from the CrossSelling list in the Cart and chcek that the button with the price on the sticky modal footer has updated value.
.modal-footer {
  &__actions {
    margin-top: -5px;

    // TODO: Refactor - add wrapper to footer inner elements https://github.com/restaumatic/restaumatic/pull/6621#discussion_r995518156
    .btn {
      width: 100%;
      will-change: transform;
      transform: translateZ(0);
      margin-top: 5px;

      + .btn {
        margin-left: 0;
      }

      @media (min-width: $screen-sm-min) {
        width: auto;

        + .btn {
          margin-left: 5px;
        }
      }
    }
  }

  &--sticky {
    position: sticky;
    bottom: 0;
    background-color: inherit;
    z-index: $zindex-modal + 5;
    border-radius: 0 0 $modal-dialog-border-radius $modal-dialog-border-radius;
  }
}

.modal-title {
  text-transform: $modal-title-text-transform;
}

.modal-open {
  // Special utility class for allergens popover, because it triggers a modal and therefore requires a lower z-index value.
  // Class name is general so it can be used for other similar cases.
  .u-modal-trigger {
    z-index: $zindex-modal-background - 1;
  }

  // Avoiding visibility conflicts between primary content (<main>) and role=dialog layer of document
  main {
    z-index: $zindex-ui;
  }

  // Styles required in Bootstrap 5 modals
  .modal-backdrop.show {
    opacity: 0.5;
  }

  .modal.fade.show {
    opacity: 1;
  }
}

// When showing allergens popover in a modal, we hide the button which would show another modal.
.modal .popover [data-bs-toggle="modal"] {
  display: none;
}

// Change modal transition to slide up
.modal.fade:not(.show) .modal-dialog {
  transform: translate(0, 5%);
}

.modal-header {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: none;
  border-radius: $modal-dialog-border-radius $modal-dialog-border-radius 0 0;

  > .modal-title {
    flex: 1 1 auto;
  }
}

.modal-title-wrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: map-get($spacers, 3);
}

.modal-close {
  @extend .btn-text;
  // maybe it's btn-icon?
  flex-grow: 0;
  flex-shrink: 0;
  width: 38px;
  height: 38px;
  margin-top: -4px;
  font-size: 1.2em;
}
