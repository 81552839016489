.m-rating {
  &__item {
    color: darkorange;
    cursor: pointer;

    &:focus-within,
    &:hover {
      color: orange;
    }
  }
}
