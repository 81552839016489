// This file contains general styles for lists.
// In order to add new menu styles, use `restaurant-menu` BEM block.
$m-list-gutter: $grid-gutter-width !default;
$m-list-title-font-size: $font-size-h2 !default;
$m-list-item-inner-border: false !default;
$m-list-item-border-top: 1px solid rgba(#000, 0.1) !default;
$m-list-last-border-bottom: null !default;
$m-list-title-font-weight: null !default;
$m-list-title-line-height: null !default;
$m-list-title-text-transform: null !default;
$m-list-title-text-vertical-align: middle !default;
$m-list-item-vertical-padding: floor($m-list-gutter / 1.5) !default;

.m-list {
  &__list {
    margin: 0;
    padding-left: 0;
    list-style: none;
  }

  &__item {
    padding-top: ceil($m-list-gutter / 2);
    padding-bottom: ceil($m-list-gutter / 2);
    border-top: $m-list-item-border-top;

    &:last-child {
      border-bottom: $m-list-last-border-bottom;
    }

    &--striped {
      border-color: transparent;

      &:nth-child(2n) {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }

    &--action {
      @extend .u-link-wrapper;
      display: block;

      &:hover {
        animation: pulse $base-duration;
      }
    }
  }

  &--grid,
  &--grid-md-2 {
    .m-list__item {
      display: flex;
      align-items: stretch;
      flex-flow: row nowrap;

      @media screen and (min-width: $screen-sm-min) {
        padding-left: ceil($m-list-gutter / 2);
        padding-right: ceil($m-list-gutter / 2);
      }
    }

    .m-item--grid {
      flex: 1 1 auto;
      display: flex;
    }
  }

  &--grid {
    .m-list__list {
      @media screen and (min-width: $screen-sm-min) {
        display: flex;
        align-items: stretch;
        flex-flow: row wrap;
      }
    }

    .m-list__item {
      @media screen and (min-width: $screen-sm-min) {
        flex: 1 1 (100% / 2); // default grid has 2 columns on small devices
        max-width: (100% / 2); /* fix IE 11 flex-basis bug */
      }

      @media screen and (min-width: $screen-md-min) {
        flex: 1 1 (100% / 3); // default grid has 3 columns on medium devices
        max-width: (100% / 3); /* fix IE 11 flex-basis bug */
      }

      &:where(:nth-child(3n+1)) {
        @media screen and (min-width: $screen-sm-min) {
          padding-left: 0;
        }
      }

      &:where(:nth-child(3n)) {
        @media screen and (min-width: $screen-sm-min) {
          padding-right: 0;
        }
      }
    }

    + .m-list--grid {
      border-top: 0;
    }
  }

  &--grid-md-2 {
    .m-list__list {
      @media screen and (min-width: $screen-sm-min) {
        display: flex;
        align-items: stretch;
        flex-flow: row wrap;
      }
    }

    .m-list__item {
      @media screen and (min-width: $screen-sm-min) {
        flex: 1 1 (100% / 2);
        max-width: (100% / 2); /* fix IE 11 flex-basis bug */
      }

      &:where(:nth-child(2n+1)) {
        @media screen and (min-width: $screen-sm-min) {
          padding-left: 0;
        }
      }

      &:where(:nth-child(2n)) {
        @media screen and (min-width: $screen-sm-min) {
          padding-right: 0;
        }
      }
    }
  }

  &__title {
    display: none; /* hide it because on mobile we have accordion title */
    margin: 0;
    padding-top: floor($m-list-gutter / 1.5);
    padding-bottom: floor($m-list-gutter / 1.5);
    font-weight: $m-list-title-font-weight;
    font-size: $m-list-title-font-size;
    line-height: $m-list-title-line-height;
    text-transform: $m-list-title-text-transform;

    @media screen and (min-width: $grid-float-breakpoint) {
      display: block;
    }

    &--inline-block {
      --menu-section-title-bg-color: rgba(0, 0, 0, 0.3); // It's default value and might be change in _menu.scss.liquid file
      margin: $m-list-item-vertical-padding 0 $m-list-item-vertical-padding 50%;
      padding: floor($m-list-gutter / 2);
      background-color: var(--menu-section-title-bg-color);
      transform: translateX(-50%);
      font-size: $m-list-title-font-size;
      text-align: center;

      @media screen and (min-width: $grid-float-breakpoint) {
        display: inline-block; // defined within media query because this element should only be visible on wider viewports
      }
    }
  }

  &__title-text {
    &--inline {
      vertical-align: $m-list-title-text-vertical-align;
    }
  }

  &__featured {
    &--advanced {
      position: relative;

      .m-list__title {
        &--inline-block {
          @media screen and (min-width: $grid-float-breakpoint) {
            position: relative;
            z-index: 1;
          }
        }
      }

      .m-list__picture {
        position: absolute;
        inset: 0;
      }

      .m-list__featured-image {
        object-fit: cover;
        height: 100%;
      }
    }
  }

  &__picture {
    &--gfbm { // grid-float-breakpoint-max
      @media (max-width: $grid-float-breakpoint-max) {
        display: none;
      }
    }
  }

  &__featured-image {
    @include img-responsive();
    width: 100%;
  }

  &__description {
    margin: 0;
    padding-top: ceil($m-list-gutter / 2);
    padding-bottom: ceil($m-list-gutter / 2);
    font-size: if(variable-exists(m-item-description-font-size), $m-item-description-font-size, null);
  }

  &__list--restaurants {
    margin-bottom: $m-list-gutter * 1.5;

    .m-list__item {
      border-bottom: none;
    }

    @at-root .m-list--list & {
      border-bottom: $m-list-item-border-top;
    }
  }
}

@if ($m-list-item-inner-border == true) {
  .m-item--grid {
    padding-bottom: $m-list-gutter;
    border-bottom: $m-list-item-border-top;
  }

  .m-list--grid,
  .m-list--grid-md-2 {
    padding-top: floor($m-list-gutter / 2);
    border-top: $m-list-item-border-top;

    .m-list__item {
      border: none;
    }
  }
}
