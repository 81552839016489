$m-product-card-content-last-margin-block-end: $form-group-margin-bottom !default;
$m-product-card-action-btn-bg-color: #fff !default;
$m-product-card-action-btn-icon-color: #000 !default;
$m-product-card-action-btn-size: 40px !default;
$m-product-card-action-btn-margin: 8px !default;
$m-product-card-actions-offset: 8px !default;
$m-product-card-action-btn-font-size: 18px !default;
$m-product-card-header-min-height: $m-product-card-action-btn-size +
  $m-product-card-actions-offset + (2 * $m-product-card-action-btn-margin) !default;

.m-product-card {
  &__header {
    position: relative;
    min-height: $m-product-card-header-min-height;
    margin-bottom: 8px;
  }

  &__img {
    height: 260px;
    width: 100%;
    margin: auto;
    object-fit: cover;
  }

  &__actions {
    @include position(
      absolute,
      $m-product-card-actions-offset $m-product-card-actions-offset null null
    );
    display: flex;
  }

  &__action-btn {
    height: $m-product-card-action-btn-size;
    width: $m-product-card-action-btn-size;
    margin: $m-product-card-action-btn-margin;
    border-radius: 50%;
    background-color: $m-product-card-action-btn-bg-color;
    color: $m-product-card-action-btn-icon-color;
    box-shadow: 0 4px 24px rgba(#000, 0.16);
    font-size: $m-product-card-action-btn-font-size;

    &:hover,
    &:focus,
    &:active {
      background-color: distinguishColor(
        $m-product-card-action-btn-bg-color,
        10%
      );
    }
  }

  &__body {
    padding: 8px 16px;
  }

  &__details {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    row-gap: 0.5rem;

    .label-dish {
      line-height: 1.4;
    }
  }

  &__title,
  &__desc {
    @include text-break();
  }

  &__content {
    margin-block-end: 0;

    // Styling last element with `m-product-card__content` class
    &:not(&:has(~ &)) {
      margin-block-end: $m-product-card-content-last-margin-block-end;
      border-block-end: 1px solid var(--color-separator, #eee);
    }
  }
}
