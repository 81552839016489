.m-crossup {
  padding: $base-spacing 0;

  &--with-title {
    border-top: 1px dashed $hr-border;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: ($base-spacing / 2);

    @media screen and (min-width: $screen-sm-min) {
      margin-bottom: $base-spacing;
    }
  }

  &__nav {
    display: none;

    @media screen and (min-width: $screen-sm-min) {
      display: flex;
      margin: auto 0 auto auto;
    }
  }

  &__arrow {
    width: 40px;
    height: 40px;
    margin: 0 8px;
    border: 1px solid #d2d2d2;
    border-radius: 50%;
    color: inherit;
    transition: box-shadow $base-duration;

    &:hover,
    &:focus-visible {
      box-shadow: 0px 0px 5px 0px rgba(#888, 0.6);
    }
  }

  &__body {
    @media screen and (min-width: $screen-sm-min) {
      margin-right: ($grid-gutter-width / -2);
      margin-left: ($grid-gutter-width / -2);
    }
  }

  &__item {
    padding: ($grid-gutter-height / 2) 0;
    border-bottom: 1px dashed $hr-border;

    &:first-child {
      padding-top: revert;
    }

    @media screen and (min-width: $screen-sm-min) {
      height: auto;
      padding: 0 ($grid-gutter-width / 2);
      border-bottom: revert;
      border-right: 1px dashed $hr-border;
    }

    &:last-child {
      border-right: none;
      border-bottom: none;
    }
  }

  &__item-content {
    display: flex;
    flex-direction: row;

    @media screen and (min-width: $screen-sm-min) {
      flex-direction: column;
    }
  }

  &__item-picture {
    padding-right: ($grid-gutter-width / 2);
    flex-shrink: 0;

    @media screen and (min-width: $screen-sm-min) {
      padding-right: revert;
      padding-bottom: ($base-spacing / 2);
    }
  }

  &__item-img {
    width: 100px;
    border-radius: 8px;
    aspect-ratio: 1 / 1;
    object-fit: cover;

    @media screen and (min-width: $screen-sm-min) {
      width: 100%;
      aspect-ratio: 2 / 1;
    }
  }

  &__item-title {
    @include text-break();
    @include line-clamp(2);
    line-height: $line-height-base;
  }

  &__item-desc {
    @include text-break();
    color: var(--text-muted);
  }

  &__counter-btn--add {
    color: inherit;
    border-color: var(--color-primary, $brand-primary);
  }

  &__price {
    color: var(--text-muted);
  }

  // REDESIGNED (used by Preact components)
  &.m-crossup--redesigned & {
    $crossup-border-color: var(--color-separator, #eee);
    $crossup-item-padding: $grid-gutter-width / 2;
    $crossup-image-radius: $border-radius-base;
    $crossup-item-radius: $crossup-image-radius + $crossup-item-padding / 2;

    @at-root {
      .m-crossup--redesigned {
        padding: $form-group-margin-bottom 0 0;
        border-top: 1px solid $crossup-border-color;
      }
    }

    &__body {
      margin: 0;
    }

    &__item {
      padding: 0;
      border: 0;
      margin-bottom: $base-spacing / 2;

      @media screen and (min-width: $screen-sm-min) {
        margin-bottom: 0;
      }
    }

    &__item-content {
      padding: $crossup-item-padding;
      border: 1px solid $crossup-border-color;
      border-radius: $crossup-item-radius;

      &.is-active {
        border-color: var(--color-primary, $brand-primary);
      }
    }

    &__item-img {
      border-radius: $crossup-image-radius;
    }
  }
}
