// Numeric stepper used to increment or decrement numeric values.
// Used in Product Creator (modifiers, quantity), CrossSelling (quantity) and Cart (product items quantity).

// 1. Hardcoded value similar to `creator.scss` or Bootstrap defaults (TODO: consider refactor depending on final design).

.m-numeric-stepper {
  display: inline-flex;
  align-items: center;

  &__count {
    display: inline-block;
    margin-right: 0.3125em;
    margin-left: 0.3125em;
    min-width: 1.5ch;
    text-align: center;
  }

  &__btn {
    @extend %button-unstyled;
    font-size: 1em;
    font-weight: $font-weight-base;
    border: 1px solid #d8d8d8; // [1]
    background-color: #fafafa; // [1]
    width: 2.5em;
    height: 2.5em;
    border-radius: 50%;

    &:focus,
    &:active {
      outline: none;
      border-color: var(--color-primary, $brand-primary);
    }

    &:focus-visible {
      border-width: 2px;
    }

    &[disabled] {
      opacity: $inactive-opacity;
    }

    &--large {
      font-size: 1.2em;
    }

    .l-mobile-app--ios & {
      color: $text-color;
    }
  }
}
