// 1. This value can be overwritten by the user in theme settings (theme.scss.liquid)

// Display large hero cover with media file (image or video)
.m-cover-media {
  position: relative;
  display: flex;
  min-height: 320px;
  align-items: stretch;
  justify-content: center;
  background-color: #000; // [1]

  @media (min-height: $screen-sm-min), (min-width: $screen-sm-min) {
    min-height: 440px;
  }

  // Required for legacy browsers in order to simulate object-fit behavior and support long dropdowns
  &__item-wrapper {
    @include position(absolute, 0 0 0 0);
    overflow: hidden;
  }

  &__item {
    @include position(absolute, 0 0 0 0);
    min-width: 100%;
    min-height: 100%;
    max-width: none;
    opacity: .3; // [1]

    @supports (object-fit: cover) {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }

  &__content {
    max-width: 100vw;
  }

  &--pasja {
    flex-grow: 1;
  }
}
