.alert-danger .btn-search-restaurant {
  display: none;
}

.order-result {
  text-align: center;

  .timer {
    display: inline-block;
    margin-bottom: 1em;
  }

}
