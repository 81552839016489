$u-text-important: #d9534f !default;

//
// Utility classes with `u-` prefix
// The highest priority utilities with single responsibility
// ----------------------------------------------------------

.u-text-normal {
  font-weight: $font-weight-base !important;
}

.u-text-bold {
  font-weight: $font-weight-bold !important;
}

.u-text-uppercase {
  text-transform: uppercase !important;
}

.u-text-capitalize {
  text-transform: capitalize !important;
}

.u-text-underline {
  text-decoration: underline !important;
}

.u-text-lowercase {
  text-transform: lowercase !important;
}

.u-text-transform-none {
  text-transform: none !important;
}

.u-text-important {
  color: $u-text-important !important;
}

.u-text-break {
  @include text-break();
}

.u-text-ellipsis {
  @include text-ellipsis();
}

.u-full-width {
  width: 100% !important;
}

.u-full-height {
  height: 100% !important;
}

.u-max-width-none {
  max-width: none !important;
}

.u-disable-transition {
  transition: none !important;
}

.u-border-radius {
  border-radius: $border-radius-base !important;
}

.u-border-none {
  border: none !important;
}

.u-rounded-circle {
  border-radius: 50% !important;
}

.u-inline-block {
  display: inline-block !important;
}

.u-block {
  display: block !important;
}

.u-resize-none {
  resize: none !important;
}

.u-va-top {
  vertical-align: top !important;
}

.u-va-middle {
  vertical-align: middle !important;
}

.u-flip-horizontal {
  transform: scaleX(-1) !important;
}

.u-flip-vertical {
  transform: scaleY(-1) !important;
}

.u-text-center {
  text-align: center !important;
}

.u-text-left {
  text-align: left !important;
}

.text-sm-left {
  @media (min-width: $screen-sm-min) {
    text-align: left !important;
  }
}

.u-text-right {
  text-align: right !important;
}

.u-optional-content {
  // Note: keep in mind access in visual editor (edit mode).
  &:empty {
    margin: 0 !important;
    padding: 0 !important;
    height: auto !important;
    min-height: 0 !important;
    min-width: 0 !important;
    flex: 0 1 0 !important;
  }
}

.u-bg-size-cover {
  background-size: cover !important;
}

.u-text-nowrap {
  white-space: nowrap !important;
}

.u-text-wrap {
  white-space: normal !important;
}

.u-base-spacing-t {
  margin-top: $base-spacing !important;
}

.u-base-spacing-b {
  margin-bottom: $base-spacing !important;
}

.u-small-spacing-t {
  margin-top: floor($base-spacing / 2) !important;
}

.u-base-spacing-y {
  margin-top: $base-spacing !important;
  margin-bottom: $base-spacing !important;
}

.u-small-spacing-b {
  margin-bottom: floor($base-spacing / 2) !important;
}

.u-small-spacing-y {
  margin-top: floor($base-spacing / 2) !important;
  margin-bottom: floor($base-spacing / 2) !important;
}

.u-static {
  position: static !important;
}

.u-relative {
  position: relative !important;
}

.u-fixed {
  position: fixed !important;
}

.u-sticky-top {
  position: sticky !important;
  top: 0 !important;
}

.u-flex-grow {
  flex-grow: 1 !important;
}

.u-flex-grow-0 {
  flex-grow: 0 !important;
}

.u-flex-shrink {
  flex-shrink: 1 !important;
}

.u-flex-shrink-0 {
  flex-shrink: 0 !important;
}

.u-hyphens {
  word-wrap: break-word; /* simple fallback for `hyphens` */
  hyphens: auto !important;
}

.u-blend-lighten {
  mix-blend-mode: lighten !important;
}

.u-blend-darken {
  mix-blend-mode: darken !important;
}

.u-color-inherit {
  color: inherit !important;
}

.u-bg-color-inherit {
  background-color: inherit !important;
}

.u-base-font-size {
  font-size: 1rem !important;
}

.u-font-size-inherit {
  font-size: inherit !important;
}

.u-font-size-120p {
  font-size: 120% !important;
}

.u-font-size-small {
  font-size: $font-size-small !important;
}

.u-cap-first-letter {
  &:first-letter {
    text-transform: uppercase;
  }
}

@each $bname, $bvalue in $grid-breakpoints {
  @include media-breakpoint-up($bname) {
    $bname: if($bname != 0, "-#{$bname}-", "");
    @each $key, $value in $spacers {
      // Margin spacers
      .u-m#{$bname}#{$key} {
        margin: $value !important;
      }

      .u-mt#{$bname}#{$key} {
        margin-top: $value !important;
      }

      .u-mr#{$bname}#{$key} {
        margin-right: $value !important;
      }

      .u-mb#{$bname}#{$key} {
        margin-bottom: $value !important;
      }

      .u-ml#{$bname}#{$key} {
        margin-left: $value !important;
      }

      .u-mx#{$bname}#{$key} {
        margin-right: $value !important;
        margin-left: $value !important;
      }

      .u-my#{$bname}#{$key} {
        margin-top: $value !important;
        margin-bottom: $value !important;
      }

      // Negative margin spacers
      .u-nm#{$bname}#{$key} {
        margin: -$value !important;
      }

      .u-nmt#{$bname}#{$key} {
        margin-top: -$value !important;
      }

      .u-nmr#{$bname}#{$key} {
        margin-right: -$value !important;
      }

      .u-nmb#{$bname}#{$key} {
        margin-bottom: -$value !important;
      }

      .u-nml#{$bname}#{$key} {
        margin-left: -$value !important;
      }

      .u-nmx#{$bname}#{$key} {
        margin-right: -$value !important;
        margin-left: -$value !important;
      }

      .u-nmy#{$bname}#{$key} {
        margin-top: -$value !important;
        margin-bottom: -$value !important;
      }

      // Padding spacers
      .u-p#{$bname}#{$key} {
        padding: $value !important;
      }

      .u-pt#{$bname}#{$key} {
        padding-top: $value !important;
      }

      .u-pr#{$bname}#{$key} {
        padding-right: $value !important;
      }

      .u-pb#{$bname}#{$key} {
        padding-bottom: $value !important;
      }

      .u-pl#{$bname}#{$key} {
        padding-left: $value !important;
      }

      .u-px#{$bname}#{$key} {
        padding-right: $value !important;
        padding-left: $value !important;
      }

      .u-py#{$bname}#{$key} {
        padding-top: $value !important;
        padding-bottom: $value !important;
      }
    }
  }
}

.u-clear-both {
  clear: both !important;
}

.u-progress {
  &,
  &.disabled,
  &[disabled] {
    cursor: progress !important;
  }
}

//
// Visibility classes for mobile application.
// ----------------------------------------------------------

// Show element only if the site is displayed in mobile application.
.u-visible-app {
  display: none !important;

  @at-root .l-mobile-app & {
    display: block !important;
  }
}

// Hide element if the site is displayed in mobile application.
.u-hidden-app {
  @at-root .l-mobile-app & {
    display: none !important;
  }
}

//
// Visibility classes for Active Menu.
// ----------------------------------------------------------
.u-visible-am {
  display: none !important;

  @at-root .l-active-menu & {
    display: block !important;
  }
}

// Hide element if the site is displayed in mobile application.
.u-hidden-am {
  @at-root .l-active-menu & {
    display: none !important;
  }
}

// General utilities
// More rules or lower priority (without important flag)
// ----------------------------------------------------------
.u-center {
  @include u-center;
}

.u-link-wrapper {
  &,
  &:hover,
  &:focus {
    color: inherit;
    text-decoration: none;
    font-weight: inherit;
  }

  &:focus {
    outline: $outline-default;
    outline-offset: -2px;
  }
}

.u-list-unstyled {
  margin: 0;
  padding: 0;
  list-style: none;
}

.u-list-inline {
  @extend .list-inline;
  margin: 0;
}

.u-text-unstyled,
.u-link-unstyled {
  color: inherit;
  text-decoration: none;
}

.u-text-decoration-none {
  text-decoration: none !important;
}

.u-btn-unstyled {
  @extend %button-unstyled;
}

.u-text-color {
  color: $text-color; // be careful with that, in most cases it's better to use variable that includes theme settings in theme.scss.liquid
}

.u-sup {
  vertical-align: super !important;
  font-size: smaller !important;
}

.u-hover-opacity {
  &:hover,
  &:focus {
    opacity: $hover-opacity;
  }
}

.u-no-hover-opacity {
  opacity: $hover-opacity;

  &:hover,
  &:focus {
    opacity: 1;
  }
}

.u-order-first {
  order: -1 !important;
}

.u-order-last {
  order: 9999 !important;
}

@media (min-width: $screen-sm-min) {
  .u-order-sm-first {
    order: -1 !important;
  }

  .u-order-sm-last {
    order: 9999 !important;
  }
}

@media (min-width: $screen-md-min) {
  .u-order-md-first {
    order: -1 !important;
  }

  .u-order-md-last {
    order: 9999 !important;
  }
}

@media (min-width: $screen-lg-min) {
  .u-order-lg-first {
    order: -1 !important;
  }

  .u-order-lg-last {
    order: 9999 !important;
  }
}

.u-overflow-hidden {
  overflow: hidden !important;
}

.u-dummy-filler {
  display: inline-block !important;
  visibility: hidden !important;
  margin: 0 !important;
  padding: 0 !important;
  height: 0 !important;
  overflow: hidden !important;
}

.u-full-width-mobile {
  @media (max-width: $screen-sm-max) {
    width: 100% !important;
  }
}

.u-legend-float {
  float: left; // reset some native positioning, like margin overlapping with a parent `fieldset (requires width: 100% – already included in `Normalize.css`)`

  + * {
    clear: both; // reqired if `legend` has `float`
  }
}

.u-childs-separator {
  > *:not(:last-child) {
    border-bottom: 1px solid var(--color-separator, $separator-color);
    padding-bottom: $base-spacing;
    margin-bottom: $base-spacing;
  }
}

.u-box-shadow-none {
  box-shadow: none !important;
}

.u-visibility-hidden {
  visibility: hidden !important;
}

.u-visibility-visible {
  visibility: visible !important;
}

.u-line-height-1 {
  line-height: 1 !important;
}
