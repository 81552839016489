$m-recommended-items-number-images-in-row: 2;

.m-recommended-items {
  &__content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;
    
    @media screen and (min-width: $grid-float-breakpoint) {
      flex-direction: row;
    }
  }

  &__header {
    text-transform: uppercase;
    letter-spacing: 3px;
    border-bottom: solid 3px $brand-secondary;
    margin-bottom: 36px
  }

  &__list {
    list-style: none;
    padding-left: initial;

    @media screen and (min-width: $grid-float-breakpoint) {
     @include pasja-box-shadow(10px);
      max-width: 40%;
      padding-bottom: $grid-gutter-height;
      padding-left: $grid-gutter-width * 2;
      padding-right: $grid-gutter-width;
    }
  }

  &__list-item {
    border-bottom: solid 3px $brand-secondary;
    margin: 10px 0;
  }

  &__item {
    padding: 5px 0;
  }

  &__item-name {
    text-transform: uppercase;
    font-size: $font-size-large;
    font-weight: $font-weight-bold;
    color: $brand-complementary;
  }

  &__item-desc {
    font-size: $font-size-small-unit;
  }

  &__gallery {
    display: grid;
    grid-template-columns: repeat($m-recommended-items-number-images-in-row, 1fr);
    grid-gap: 10px;
    align-self: center;

    @media screen and (min-width: $grid-float-breakpoint) {
      padding-left: $grid-gutter-width;
      max-width: 60%;
    }
  }
}
