.dish-photo-popover.popover.top .popover-arrow {
  left: 15px;
}

.dish-photo-popover {
  z-index: 1031;
  max-width: 500px;

  .popover-body {
    padding: 10px;
    //width: 480px;

    img {
      @include img-responsive();
    }
  }

  .close {
    color: black;
    opacity: 1;
    background: white;
    border-radius: 23px;
    padding: 5px 10px;
    position: absolute;
    right: 3px;
    top: 1px;
  }

}

.dish-image {
  margin: 5px;
}

tr.dish > td:first-of-type, tr.pizza > td:first-of-type {
  padding: 0;
  vertical-align: middle;
}

