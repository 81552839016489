$m-promo-section-inner-max-width: $container-large-desktop !default;
$m-promo-section-font-size: 34px !default;
$m-promo-section-font-weight: null !default;
$m-promo-section-text-transform: null !default;
$m-promo-section-image-margin-gfb: null !default;
$m-promo-section-gutter-v: 50px !default;
$m-promo-section-text-mb: ($line-height-computed / 2) !default;
$m-promo-section-text-align-gfb: center !default;

.m-promo-section {
  text-align: center;
  font-weight: $m-promo-section-font-weight;
  text-transform: $m-promo-section-text-transform;

  @media screen and (min-width: $grid-float-breakpoint) {
    text-align: $m-promo-section-text-align-gfb;
  }

  &__inner {
    background-position: 50% 50%;
  }

  &__container {
    @include container-fixed(0);
    max-width: $m-promo-section-inner-max-width;
    padding-top: $m-promo-section-gutter-v;
    padding-bottom: $m-promo-section-gutter-v;

    @media screen and (min-width: $grid-float-breakpoint) {
      padding-top: floor($m-promo-section-gutter-v * 1.8);
      padding-bottom: floor($m-promo-section-gutter-v * 1.8);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__item {
    padding-right: 30px;
    padding-left: 30px;
    max-width: 100%;
    line-height: 1.5;

    @media screen and (min-width: $grid-float-breakpoint) {
      flex: 1 1 0;

      &:first-child {
        display: flex;
        justify-content: flex-end;
      }
    }

    @at-root .m-promo-section--simple & {
      display: block;
      padding-right: 0;
      padding-left: 0;
    }
  }

  &__item-inner {
    @at-root .m-promo-section--simple & {
      margin: 0 floor($grid-gutter-width / -4);
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      text-align: center;

      @media screen and (min-width: $grid-float-breakpoint) {
        text-align: $m-promo-section-text-align-gfb;
      }

      > * {
        margin-bottom: 0;
        padding-right: floor($grid-gutter-width / 4);
        padding-left: floor($grid-gutter-width / 4);
      }
    }
  }

  &__image {
    @include img-responsive();
    margin: $m-promo-section-image-margin-gfb;

    @media screen and (max-width: $grid-float-breakpoint-max) {
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__text {
    margin-top: 0;
    margin-bottom: $m-promo-section-text-mb;
    font-size: $m-promo-section-font-size;
  }

  &__action {
    display: inline-block;
  }

  &--simple {
    margin-bottom: $base-spacing;
    padding: floor($grid-gutter-height / 2) 0;
    border-top: 1px solid;
    border-bottom: 1px solid;
  }
}
