
.wysiwyg-color-black {
  color: black;
}

.wysiwyg-color-silver {
  color: silver;
}

.wysiwyg-color-gray {
  color: gray;
}

.wysiwyg-color-white {
  color: white;
}

.wysiwyg-color-maroon {
  color: maroon;
}

.wysiwyg-color-red {
  color: red;
}

.wysiwyg-color-purple {
  color: purple;
}

.wysiwyg-color-fuchsia {
  color: fuchsia;
}

.wysiwyg-color-green {
  color: green;
}

.wysiwyg-color-lime {
  color: lime;
}

.wysiwyg-color-olive {
  color: olive;
}

.wysiwyg-color-yellow {
  color: yellow;
}

.wysiwyg-color-navy {
  color: navy;
}

.wysiwyg-color-blue {
  color: blue;
}

.wysiwyg-color-teal {
  color: teal;
}

.wysiwyg-color-aqua {
  color: aqua;
}

.wysiwyg-color-orange {
  color: orange;
}

.wysiwyg-text-align-center {
  text-align: center;

  img {
    margin-right: auto;
    margin-left: auto;
  }
}
.wysiwyg-text-align-left {
  text-align: left;

  img {
    margin-right: auto;
  }
}
.wysiwyg-text-align-right {
  text-align: right;

  img {
    margin-left: auto;
  }
}
