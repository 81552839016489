.m-footer {
  padding: 57px 0;
  @include font-smoothing;

  &--blend-mode {
    background-blend-mode: color-dodge;
  }

  @media screen and (min-width: $grid-float-breakpoint) {
    padding-top: 95px;
  }

  &__content {
    margin-bottom: 48px;
    font-size: 1.125rem;
    color: #fff;

    p {
      margin-bottom: $form-group-margin-bottom;
    }
  }

  &__info {
    font-size: $font-size-small-unit;
    color: #fff;

    p {
      margin: 0;
    }
  }

  .m-select {
    border-radius: $border-radius-base;

    &__select {
      border-radius: $border-radius-base;
      border-width: 1px;
      //color: $text-color; // defined in theme.scss.liquid
    }
  }

  strong {
    font-weight: 400;
  }

  label {
    text-transform: uppercase;
  }

  &__link {
    @include text-break();
    color: #fff;
    text-decoration: underline;

    &:focus,
    &:hover {
      //color: $link-color; // defined in theme.scss.liquid
      text-decoration: none;
    }
  }

  .m-icon-group {
    margin-top: 8px;
    font-size: 2.25rem;

    &__list-item {
      &:not(:first-child) {
        padding-left: 0;
      }
    }

    a {
      //color: lighten($brand-complementary, 40%); // defined in theme.scss.liqud

      &:focus,
      &:hover {
        //color: $link-color; // defined in theme.scss.liqud
      }
    }
  }

  &__row {
    @include list-unstyled;
    margin: 0;

    @media screen and (min-width: $screen-xs-min) {
      display: flex;
      flex-wrap: wrap;
      margin-right: floor($grid-gutter-width / -2);
      margin-left: ceil($grid-gutter-width / -2);
    }
  }

  &__col {
    @media screen and (min-width: $screen-xs-min) {
      position: relative;
      padding-right: ($grid-gutter-width / 2);
      padding-left: ($grid-gutter-width / 2);
      flex: 1 0 auto;

      &--restaurants {
        flex-basis: 250px;
      }

      &--icons {
        flex: 0 0 auto;
        width: auto;
        white-space: nowrap;
      }
    }

    &--grow {
      @media screen and (min-width: $grid-float-breakpoint) {
        flex-grow: 3;
      }
    }
  }
}
