.m-language-select {
  &__list {
    @include list-unstyled;
    margin: 0;
  }

  &__dropdown {
    &:not(li) {
      display: inline-block;
    }
  }

  &__dropdown-toggle {
    display: block;
    color: inherit;
    text-decoration: none;
    white-space: nowrap;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  &__locale-selected-image {
    display: inline-block;
    width: 2em;

    &--rounded {
      border-radius: 50%;
      height: 2em;
    }
  }

  &__caret {
    display: inline-block;
  }

  &__dropdown-menu {
    min-width: 50px;
    animation: fadeIn $base-duration;

    // Override dropdown-menu styles from Bootstrap
    > li {
      > .m-language-select__item {
        padding-right: 8px;
        padding-left: 8px;
      }
    }
  }

  &__locale-image {
    display: block;
    margin-right: auto;
    margin-left: auto;
    width: 2em;

    &--rounded {
      border-radius: 50%;
      height: 2em;
    }
  }

  &__item {
    display: block;
  }
}
