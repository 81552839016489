.m-simple-gallery {
  position: relative;

  &:before {
    position: absolute;
    top: calc(50% + #{$grid-gutter-width * 1.5});
    left: 0;
    right: 0;
    bottom: floor($grid-gutter-width / -1.5);
    content: "";

    @media screen and (min-width: $screen-xs-min) {
      top: 50%;
    }
  }

  &__list {
    @include list-unstyled;
    margin-right: $grid-gutter-width / -2;
    margin-left: $grid-gutter-width / -2;
    position: relative;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0;
    text-align: center;

    @media screen and (min-width: $screen-xs-min) {
      flex-wrap: nowrap;
    }
  }

  &__list-item {
    padding-right: $grid-gutter-width / 2;
    padding-left: $grid-gutter-width / 2;
    display: inline-block;
    margin-bottom: $grid-gutter-width;
    width: 50%;

    @media screen and (min-width: $screen-xs-min) {
      width: auto;
    }
  }

  &__item-link {
    position: relative;
    display: block;
    background-color: $body-bg;
    border: 0 solid #fff;
    transition: border-width $base-duration, box-shadow $base-duration, transform $base-duration;

    &:focus,
    &:hover {
      $box-shadow-offset-base: 10px;
      z-index: $zindex-ui;
      transform: scale(1.05);
      box-shadow: 0 0 0 $box-shadow-offset-base #fff,
                  (($box-shadow-offset-base * 2) / -1) ($box-shadow-offset-base * 2) 0 rgba(#000, 0.05);

      .m-simple-gallery__thumbnail-img {
        @include pasja-blend-mode(true);
      }

      &:after {
        @include position(absolute, 50% null null 50%);
        @include icon();
        margin-top: -30px;
        margin-left: -30px;
        padding-top: 15px;
        width: 60px;
        height: 60px;
        font-size: 30px;
        color: #fff;
        text-align: center;
        //background-color: $brand-primary; // defined in theme.scss.liquid
        border-radius: 50%;
        content: '\e80a';
      }
    }
  }

  &__thumbnail-img {
    @include pasja-blend-mode();
  }
}
