.m-counter {
  &__item {
    display: inline-block;
    margin: 1px;
    padding: 15px;
    vertical-align: top;
    background-color: #000;
    background-image: linear-gradient(to bottom, rgba(82, 82, 82, 1) 0%, rgba(0, 0, 0, 1) 100%);
    border-radius: $border-radius-base;
    color: #fff;
  }

  &__value {
    display: block;
    margin-bottom: 10px;
    font-weight: $font-weight-bold;
    font-size: 55px;
    line-height: 1;

    @media screen and (max-width: $screen-xs-min) {
      font-size: 40px;
    }
  }

  &__label {
    display: block;
    font-size: 14px;
  }
}
