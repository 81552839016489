// This file contains general styles for items.
// In order to add new menu styles, use `restaurant-menu` BEM block.
$m-item-gutter-base: 0.5em !default;
$m-item-title-text-transform: null !default;
$m-item-title-font-size: null !default;
$m-item-description-font-size: null !default;
$m-item-price-font-color: null !default;
$m-item-font-small-size: 0.75em !default;

.m-item {
  position: relative;
  line-height: $line-height-base;

  .label-dish {
    display: inline-block;
    text-align: left;
    line-height: 1.4;
    white-space: normal;
  }

  &__row {
    width: 100%;

    &:not(.m-item__row--header) {
      @include clearfix;
    }

    @at-root .m-item--list {
      .m-item__row {
        @media screen and (min-width: $grid-float-breakpoint) {
          display: table;
        }
      }
    }

    @at-root .m-item--list-mobile {
      .m-item__row {
        display: flex;
      }
    }

    &--grid-align {
      display: flex;
      flex-direction: column;

      .m-item__col-header {
        flex-grow: 1;
      }
    }
  }

  &__col-header {
    @at-root .m-item--list {
      .m-item__col-header {
        @media screen and (min-width: $grid-float-breakpoint) {
          display: table-cell;
          padding: 0 $m-item-gutter-base 0 0;
        }
      }
    }
  }

  &__col-group-info {
    display: inline-block;
    padding: 0.125em $m-item-gutter-base 0 0;

    @at-root .m-item--list {
      .m-item__col-group-info {
        @media screen and (min-width: $grid-float-breakpoint) {
          display: table-cell;
          text-align: center;
        }

        &--secondary {
          @media screen and (min-width: $grid-float-breakpoint) {
            width: 9%;
          }

          @media screen and (min-width: $screen-md-min) {
            width: 7%;
          }
        }
      }
    }
  }

  &__col {
    @media screen and (max-width: $grid-float-breakpoint-max) {
      padding-top: $m-item-gutter-base;
    }

    @at-root .m-item--grid {
      .m-item__col {
        padding-top: $m-item-gutter-base;

        &--empty {
          display: none;
        }
      }
    }

    @at-root .m-item--list {
      .m-item__col {
        @media screen and (min-width: $grid-float-breakpoint) {
          display: table-cell;
          text-align: center;

          &--secondary {
            width: 14%;
            padding-left: $m-item-gutter-base;
            text-align: right;
          }

          .dropdown-menu {
            min-width: 130px;
          }

          &--regular-align {
            text-align: left;
          }

          &--opposite-align {
            text-align: right;
          }
        }

        &--empty {
          @media screen and (max-width: $grid-float-breakpoint-max) {
            display: none;
          }
        }
      }
    }

    @at-root .m-item--list-mobile {
      .m-item__col {
        flex: 0 0;
        text-align: center;

        &--main {
          flex-grow: 1;
        }

        &--regular-align {
          text-align: left;
        }

        &--opposite-align {
          text-align: right;
        }
      }
    }

    &--secondary {
      &.actions {
        @media screen and (max-width: $grid-float-breakpoint-max) {
          margin-left: auto;
          padding-left: $m-item-gutter-base;
          float: right;
        }
      }
    }
  }

  /* Fill the space with dummy element to make sure that columns are aligned properly like in "table" layout. */
  &__col-dummy-filler {
    display: none;

    @at-root .m-item--list {
      .m-item__col-dummy-filler {
        /* element is required only in list mode */
        @media screen and (min-width: $grid-float-breakpoint) {
          display: inline-block;
          visibility: hidden;
          margin-top: 0;
          margin-bottom: 0;
          padding-top: 0;
          padding-bottom: 0;
          height: 0;
          overflow: hidden;
        }
      }
    }
  }

  // remove it after enabling widget and labels
  &__special {
    margin-bottom: 0.7143em;

    @at-root .m-item--list {
      .m-item__special {
        line-height: 1;
      }
    }

    &:empty {
      @media screen and (max-width: $grid-float-breakpoint-max) {
        margin-bottom: 0;
      }
    }
  }

  &__labels {
    .label {
      margin-bottom: 0.7143em;
    }

    @at-root .m-item--list {
      .m-item__labels {
        line-height: 1;
      }
    }

    &:empty {
      @media screen and (max-width: $grid-float-breakpoint-max) {
        margin-bottom: 0;
      }
    }
  }

  &__image {
    margin-bottom: $m-item-gutter-base;
  }

  &__image-img {
    @include img-responsive();
  }

  &__image-aside {
    float: left;
    margin-right: $m-item-gutter-base;
    padding-bottom: $m-item-gutter-base;

    @at-root .m-item--list {
      .m-item__image-aside {
        @media screen and (min-width: $grid-float-breakpoint) {
          float: none;
          display: table-cell;
          width: 5%;
          margin-right: 0;
          padding: 0 $m-item-gutter-base 0 0;
          text-align: center;
        }

        &--thumbnail {
          width: 150px;
        }
      }
    }

    &--thumbnail {
      float: none;
      width: 150px;
      vertical-align: top;

      @media screen and (max-width: $grid-float-breakpoint-max) {
        @at-root .m-item--list & {
          float: left;
          max-width: 22vw;
          margin-right: $m-item-gutter-base;
        }
      }
    }
  }

  &__image-placeholder {
    @media screen and (max-width: $grid-float-breakpoint-max) {
      display: none;
    }
  }

  &__title {
    @include text-break();
    margin: 0 0 0.3125em;
    font-size: $m-item-title-font-size;
    text-transform: $m-item-title-text-transform;
  }

  &__badge {
    @extend .badge;
  }

  &__price {
    color: $m-item-price-font-color;
    white-space: nowrap;
  }

  &__description {
    @include text-break();
    margin: 0;
    font-size: $m-item-description-font-size;
  }

  &--grid {
    // Restore default `.dropdown-menu` position (override menu.scss declaration)
    .dropdown-menu {
      right: 0;
      left: auto;
    }
  }
}
