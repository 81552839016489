.m-tabs {
  &__panel {
    transition: opacity $base-duration ease-in-out;
    
    &:not(.is-active) {
      height: 0;
      visibility: hidden;
      opacity: 0;
    }

    &.is-active {
      height: auto;
      visibility: visible;
      opacity: 1;
    }
  }
}
