.delivery {

  .reservation-ok, .delivery-ok {
    margin: 25px;
    text-align: center;
  }

  .reservation-ok h3, .delivery-ok h3 {
    text-transform: uppercase;
  }


  .details {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;

    .detail {
      flex: 1 30%;
    }
  }
  .delivery-ok {
    color: green;
  }

  .delivery-button {
    margin: 20px;
  }

  .dropdown-menu {
    text-align: left;
  }
}

.m-delivery-address {
  &__container {
    display: flex;
    flex-wrap: wrap;
    margin-right: floor($form-group-margin-bottom / -2);
    margin-left: ceil($form-group-margin-bottom / -2);

    label {
      white-space: nowrap;
    }
  }

  &__item {
    padding-right: floor($form-group-margin-bottom / 2);
    padding-left: ceil($form-group-margin-bottom / 2);
    flex: 1 1 auto;
    width: auto;
    max-width: 100%;

    &--street-number {
      flex-basis: 7em;

      @media screen and (min-width: $grid-float-breakpoint) {
        flex-grow: 0;
      }
    }

    &--post-code {
      flex-basis: 9em;

      @media screen and (min-width: $grid-float-breakpoint) {
        flex-grow: 0;
      }
    }

    &--city,
    &--street {
      flex-basis: 16em;
    }
  }

  &__br {
    width: 100%;
  }

  &--condensed {
    @media screen and (min-width: $screen-sm-min) {
      display: flex;
      flex-wrap: wrap;
    }

    .m-delivery-address {
      &__container {
        @media screen and (min-width: $screen-sm-min) {
          margin-right: floor($form-group-margin-bottom / 2);
          flex-grow: 1;
        }
      }

      // Just a placeholder to align submit button in condensed mode (select restaurant view).
      &__submit-label-placeholder {
        display: none;

        @media screen and (min-width: $screen-sm-min) {
          display: block;
          visibility: hidden;
        }
      }

      // Display status below the rest of the form in condensed mode (select restaurant view).
      &__status {
        @media screen and (min-width: $screen-sm-min) {
          width: 100%;
          order: 100; // it has to be the last element
        }
      }

      &__item--city {
        flex-basis: auto;
      }

      &__br {
        display: none;
      }
    }
  }
}
