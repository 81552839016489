.m-header {
  display: flex;
  flex-direction: column;

  &__main {
    text-align: center;

    &.is-affixed {
      position: static;

      @media screen and (min-width: $screen-lg-min) {
        @include position(fixed, 0 0 null 0);
        margin-top: 0;
        padding-top: 6px;
        padding-bottom: 12px;
        border-bottom: 2px solid;
        z-index: $zindex-navbar;
        animation: fadeIn $base-duration both;

        .m-header__navbar,
        .m-header__area {
          margin: 0;
        }

        .m-brand__logo {
          max-height: 80px;
        }

        .m-language-select {
          display: none;
        }
      }
    }

    &--simple {
      margin-top: floor($grid-gutter-height / 2);
    }
  }

  &__container {
    padding-left: floor(($grid-gutter-width / 2));
    padding-right: ceil(($grid-gutter-width / 2));
    text-align: left;

    @media screen and (min-width: $grid-float-breakpoint) {
      display: inline-flex;
      max-width: 90%;
    }

    @media screen and (min-width: $screen-sm-min) {
      width: auto;
      min-width: $container-sm;
    }

    @media screen and (min-width: $screen-md-min) {
      min-width: $container-md;
    }

    @media screen and (min-width: $screen-lg-min) {
      min-width: $container-lg;
    }

    &--full-width {
      width: 100%;
      min-width: 0;
      max-width: none;
    }
  }

  &__area {
    position: relative;
    margin-bottom: $base-spacing-large;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: $grid-float-breakpoint-max) {
      margin-bottom: $base-spacing;

      .m-brand__logo {
        max-height: 80px;
      }
    }

    @media screen and (min-width: $grid-float-breakpoint) {
      .m-brand {
        flex-shrink: 0;
      }
    }

    @at-root .m-header__main--simple &,
    &--slim {
      margin-bottom: $base-spacing;
    }
  }

  &__brand-text {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    position: relative;
    top: -30px;
    margin: 0 0 -70px;
    height: calc(1em + 30px);
    max-width: 100%;
    text-align: center;
    text-transform: uppercase;
    font-size: 85px; /* fallback */
    font-size: 16vw;
    line-height: 100%;
    pointer-events: none;
    cursor: default;
    user-select: none;
    overflow: hidden;
    white-space: nowrap; // make sure text will be in one line even in variant without flexbox

    @media screen and (min-width: $screen-md-min) {
      top: -60px;
      margin-bottom: -120px;
      font-size: 189px;
    }

    @media screen and (min-width: $screen-xlg-min) {
      margin-right: auto;
      margin-left: auto;

      @if $screen-xlg-min > 1280 {
        width: 1280px;
      } @else {
        width: $container-large-desktop + ($screen-xlg-min - $screen-lg-min);
      }
    }

    &--center {
      display: block;
      width: 100%;
    }

    &--disable {
      display: none;
    }
  }

  &__control-group {
    margin-left: auto;
    order: 100;

    @media screen and (min-width: $grid-float-breakpoint) {
      margin-left: floor($grid-gutter-width / 2);
    }

    .m-language-select {
      display: inline-block;
      margin-right: floor($grid-gutter-width / 2);
    }

    &--last {
      margin-left: auto;
      order: 100;
    }
  }

  &__aside {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__navbar {
    position: relative;
    margin-top: floor($grid-gutter-width / 2);
    display: flex;
    font-size: 1.125rem;
    transition: margin-top $base-duration;

    @media screen and (max-width: $grid-float-breakpoint-max) {
      display: none;
    }
  }

  &__navbar-action {
    margin-left: floor($grid-gutter-width / 2);

    @media screen and (max-width: $grid-float-breakpoint-max) {
      display: none;
    }
  }

  &__nav-toggle {
    &--rwd {
      @media screen and (min-width: $screen-sm-min) {
        display: none;
      }
    }

    &--on-bg {
      .m-nav-toggle__icon {
        color: #fff;
        text-shadow: 0 0 2px #000;
      }
    }
  }

  &__navbar-aside {
    margin-left: auto;

    @media screen and (max-width: $grid-float-breakpoint-max) {
      @include position (absolute, 0 0 null null);
      transform: translateY(-150%);
    }
  }
}
