.m-page-header {
  @include clearfix;
  margin-bottom: ($base-spacing * 1.9);
  text-align: center;

  &__title {
    margin: 0 0 5px;
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: $font-weight-base;

    @at-root .l-page-header-ls & {
      letter-spacing: ($pasja-letter-spacing * 1.1);
    }
  }

  &__description {
    &:last-child {
      margin-bottom: 0;
    }

    @at-root .l-page-header-ls & {
      letter-spacing: $pasja-letter-spacing;
    }
  }
}

// Add compatibility with shared modules (apply Pasja header style)
.content-header {
  @extend .m-page-header;

  h1,
  h2 {
    @extend .m-page-header__title;
  }
}
