$m-select-padding: $padding-base-vertical ($padding-base-horizontal * 2) $padding-base-vertical $padding-base-horizontal !default;
$m-select-caret: null !default; // null is default

// Fix possible readability issues in some OS/browsers that change default select and option colors based on website styles.
// 1. Fallback for IE 11 (or other old browsers).
select {
  background-color: white; // [1]
  color: black; // [1]
  background-color: unset;
  color: unset;
}

optgroup,
option {
  background-color: white; // [1]
  color: black; // [1]
  background-color: initial;
  color: initial;
}

// Native select
.m-select {
  position: relative;
  display: block;
  background-color: $input-bg;
  border-radius: $input-border-radius;

  @at-root .form-inline {
    // Adds backward compatibility with Bootstrap-based components
    .m-select {
      @media (min-width: $screen-sm-min) {
        display: inline-block;
        width: auto;
        vertical-align: middle;
      }
    }
  }

  &__select {
    @extend .form-control;
    position: relative;
    padding: $m-select-padding;
    background-color: transparent;
    text-overflow: ellipsis;
    appearance: none;

    &::-ms-expand {
      display: none;
    }
  }

  @include m-form-select-caret($m-select-caret);

  &--inline {
    display: inline-block;
  }
}

// Select2 theme
//.select2-container {
//  &--form-control {
//    @extend .select2-container--default;
//    max-width: 100%;
//
//    .select2-selection {
//      position: relative;
//      @include m-form-control;
//
//      &--multiple {
//        min-height: $input-height-base;
//        height: auto;
//        line-height: 1;
//      }
//
//      @include m-form-select-caret($m-select-caret);
//
//      .select2-selection {
//        border-color: $input-border-focus;
//
//        &__icon {
//          float: left;
//          margin-right: 7px;
//        }
//
//        &__rendered {
//          padding-left: 0;
//          color: $text-color;
//          line-height: $line-height-computed;
//        }
//
//        &__arrow {
//          display: none;
//        }
//      }
//    }
//  }
//
//  &--has-value,
//  &--open,
//  &--focus {
//    .select2-selection {
//      &--multiple {
//        .select2-selection {
//          &__custom-placeholder {
//            display: none;
//          }
//        }
//      }
//    }
//  }
//
//  // Override select2 multiple default styles
//  &--open,
//  &--focus {
//    &.select2-container {
//      &--form-control {
//        .select2-selection {
//          border-color: $input-border-focus;
//
//          &:focus {
//            outline: 0 none;
//          }
//        }
//      }
//    }
//  }
//}
