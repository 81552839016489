
tr.pizza .product-name, tr.dish .product-name {
  position: relative;
}

tr.pizza .dish-icons, tr.dish .dish-icons {
  margin-right: 5px;
  display: inline-block;
}

.dish-icons {
  @include list-unstyled;
  display: inline-block;

  &__list-item {
    display: inline-block;
    padding-left: 2px;
    padding-right: 2px;
  }

  [class^="svg-icon-"] {
    vertical-align: middle;
  }
}

tr.pizza .label.label-dish, tr.dish .label.label-dish {
  position: absolute;
  top: -11px;
  left: -2px;
  border-radius: 0;
}

.label.label-discount {
  background-color: #bf9543;
}
