
/********************** restaurant list ************/
.restaurant.panel {
  cursor: pointer;
  border-top: 1px solid rgb(245, 237, 237);
  text-align: right;
}

.restaurants .restaurant.panel {
  border-top: none;
  padding-bottom: 40px;
}


.restaurant-header {
  text-align: center;
}


.restaurant.panel p {
  text-align: center;
}

.restaurants.row-fluid {
  margin-bottom: 20px;
}
