.channels {
  overflow: hidden;
  width: 575px;
  margin: 0 auto;

  .alert {
    width: 400px;
    margin:10px 0px;
    padding: 5px
  }

  .channel-group {

    &:after {
      content: "";
      display: table;
      clear: both;
    }

    //overflow: hidden;
    margin-top: 20px;
    margin-bottom: 20px;

  }
}

.reset-channel {
  margin: 35px auto;
  a {
    line-height: 30px;
  }

  &--link {
    display: inline-block;
    padding: $padding-base-vertical $padding-base-horizontal;
  }
}

.channel {
  background-color: white;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 90%;
  border: 1px solid #DADADA;
  cursor: pointer;
  float: left;
  height: 100px;
  margin-right: 4px;
  margin-top: 4px;
  padding: 5px;
  position: relative;
  width: 127px;
  z-index: 4;

  &.selected {
    border: 2px solid #1E63A9;
    border-radius: 4px;
    margin: 3px 3px -1px -1px;
  }
  &.disabled {
    opacity: 0.5;
    text-align: center;
    cursor: text;

  }

  p.channel-label {
    border: 0 none;
    bottom: 0;
    color: #345565;
    cursor: pointer;
    font-size: 0.625em;
    font-weight: bold;
    left: 0;
    margin: 0;
    padding: 0px 1px;
    position: absolute;
    right: 0;
    text-align: center;
    line-height: 11px;
    font-size:9px;
  }

}

.has-error .channel {
  border: 1px solid red;
}

.time-alert {
  @extend .alert;
  @extend .alert-info !optional;
  margin: 20px;

  strong {
    font-size: 16px;
  }
}

.waiting-for-payment {
  font-size: 13px;
  li {
    line-height: 18px;
  }
}

.adyen, .dotpay {

  .channel-group.unavailable {
    display: none;
  }

  .header {
    display: none;
  }

  .change-channel, .form-actions, .time-alert {
    display: block !important;
  }

  .channel-group  {
    & > h4, & > hr { display: none; }
  }
}

.adyen {
  #adyen{
    display: block;
  }

  #dotpay{
    display: none;
  }

}

.dotpay {
  #adyen{
    display: none;
  }
  #dotpay{
    display: block;
  }
}

form.payment {
  .form-actions { padding: 20px; text-align: right; }
  .btn-large { width: 250px; margin-left: 10px; }
}

@media (max-width: 767px) {
  .channels {
    //width: auto;
    width: 100%;
  }

  form.payment .btn-large {
    width: 100%; margin-left: 0;
  }
}
