$m-content-grid-item-max-width: 300px;

.m-content {
  &__grid-list {
    @include list-unstyled;
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;

    &--masonry {
      display: block;
      gap: revert;

      &:not([style]) { // Plugin not initiated yet. Prevent FOUC
        visibility: hidden;
      }
    }
  }

  &__grid-item {
    .m-content__grid-list--masonry & {
      position: absolute;
    }

    &:not(.m-content__grid-item--singular) {
      &:first-child {
        @media screen and (min-width: $screen-md-min) {
          padding-top: 70px;
        }
      }
      &:nth-child(3) {
        @media screen and (min-width: $screen-md-min) {
          padding-top: 30px;
        }
      }
    }
  }

  &__content {
    @include pasja-box-shadow(10px);
    margin: 0 0 20px;
    padding: 25px 30px;
    line-height: 1.7;
    border: none; // override Bootstrap blockquote styles
    background-color: #fff;

    @media screen and (min-width: $screen-xs-min) {
      padding: 55px;
    }

    .m-content__grid-list & {
      margin: 0 auto;
      max-width: $m-content-grid-item-max-width;
    }

    @at-root .m-content__grid-item .m-content__content {
      font-size: 1.25rem;
      line-height: inherit;
    }

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }

    address {
      line-height: inherit;
    }

    > {
      h1, h2, h3, h4, h5, h6 {
        &:not([class]) {
          margin-top: 0;
        }
      }
    }

    &--connected {
      @media screen and (min-width: $screen-md-min) {
        margin-bottom: 0;
      }
    }
  }

  &__media {
    margin: -25px -30px 25px;

    @media screen and (min-width: $screen-xs-min) {
      margin: -55px -55px 50px;
    }
  }

  &__title {
    margin: 0 0 15px;
    font-size: 1.25rem;
  }

  &__description {
    //color: $brand-complementary; // defined in theme.scss.liqud
  }

  &__info {
    font-size: $font-size-small-unit;

    strong {
      font-weight: bold;
    }

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  &__section {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  &__author {
    display: block;
  }

  &__rating {
    margin-bottom: 15px;
    //color: $brand-primary; // defined in theme.scss.liquid
    font-size: 0.75rem;
  }

  &__footer {
    margin-top: 10px;

    &:before {
      display: none; // override Bootstrap blockquote styles
    }

    &--advanced {
      margin-top: 22px;
    }
  }

  &__annotation {
    margin: 30px 0 0;
    padding: 0;
    border: 0; // override Bootstrap blockquote styles

    p {
      margin: 0;
    }

    .m-author {
      &:before {
        content: '\2014 \00A0'; // em dash, nbsp
      }
    }

    &:before {
      display: block;
      margin-bottom: 25px;
      height: 2px;
      width: 50px;
      //background-color: $brand-secondary; // defined in theme.scss.liquid
      content: " ";
    }
  }

  &__annotation-content {
    font-size: $font-size-base;
    font-weight: normal;
    //color: $brand-complementary; // defined in theme.scss.liqud
  }

  &__collapse {
    @include pasja-box-shadow(5px);
    @include position(absolute, 100% 0 null 0);
    z-index: $zindex-ui + 10;
    padding: 0;
  }

  .content-content {
    @extend .m-content__content;
  }

  // Spacing for legacy code (shared partials) if content from many restaurants is displayed at the same time.
  + .content-header,
  > h2 {
    margin-top: ($base-spacing * 1.9);
  }
}
