.m-review {
  max-width: 100%;

  &--with-border {
    border: 1px solid var(--color-separator);
  }

  &__footer {
    display: flex;
    justify-content: space-between;
  }

  &__author {
    @include text-break();
  }
}

.m-review-wrapper {
  max-width: 600px;
}

// Reset blockquote tag styles
blockquote.m-review__content {
  border: revert;
  font-size: unset;

  footer {
    color: inherit;
    font-size: revert;

    &::before {
      content: none;
    }
  }

  .small {
    &::before {
      content: none;
    }
  }
}
