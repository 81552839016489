$m-section-zindex: $zindex-ui !default;

.m-section {
  position: relative;
  margin-bottom: floor($base-spacing * 2);

  &--large {
    margin-bottom: floor($base-spacing * 3);
  }

  &--connected-bottom-more,
  &--connected-bottom {
    z-index: $m-section-zindex;
    margin-bottom: floor($base-spacing * -2);
  }

  &--connected-bottom-more {
    margin-bottom: floor($base-spacing * -3);
  }

  &--connected-top {
    position: relative;
    z-index: $m-section-zindex;
    margin-top: floor($base-spacing * -3);
  }

  &--under {
    z-index: ($m-section-zindex - 1);
  }

  &--no-margin {
    margin: 0;
  }

  &--fullscreen {
    display: flex;
    flex-direction: column;
    min-height: 100vh; // simple fallback for `svh`
    min-height: 100svh;
  }
}
