$m-attention-padding: 30px !default;
$m-attention-color: inherit !default;
$m-attention-heading-color: inherit !default;
$m-attention-bg: rgba(#000, 0.05) !default;
$m-attention-font-size: ceil(($font-size-base * 1.4)) !default;
$m-attention-heading-font-size: ceil(($font-size-base * 2)) !default;
$m-attention-border-radius: $border-radius-base !default;
$m-attention-breakpoint: $grid-float-breakpoint !default;

.m-attention {
  color: $m-attention-color;
  font-size: $m-attention-font-size;
  background-color: $m-attention-bg;

  &__area {
    padding: $m-attention-padding;
    border-radius: $m-attention-border-radius;

    @media screen and (min-width: $grid-float-breakpoint) {
      padding: floor($m-attention-padding * 1.6);
    }
  }

  &__title {
    margin-top: 0;
    margin-bottom: ceil($m-attention-padding / 1.5);
    font-size: $m-attention-heading-font-size;

    @media screen and (max-width: $screen-xs-min - 1) {
      word-wrap: break-word;
    }

    @media screen and (max-width: $grid-float-breakpoint-max) {
      hyphens: auto;
    }
  }

  &__paragraph {
    margin-bottom: ceil($m-attention-padding / 1.5);

    &--last {
      margin-bottom: 0;
    }
  }

  &__action {
    display: inline-block;
    vertical-align: middle;
  }
}
