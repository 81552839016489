$base-duration: 0.3s !default;

.m-placeholder {
  position: relative;
  display: block;
  overflow: hidden;
  padding-bottom: 75%;
  height: 0;
  background-color: rgba(#000, 0.05);
  background-size: contain;
  box-shadow: inset 0 0 0 1px rgba(#fff, 0.1);

  &__overlay {
    @include position(absolute, 0 0 0 0);
    background-color: rgba(#000, 0.5);
    color: #fff;
    text-decoration: none;
    opacity: 0;
    transition: opacity $base-duration;

    &:hover {
      opacity: 1;
    }
  }

  &__item {
    @include position(absolute, 50% 0 null 0);
    text-align: center;
    transform: translateY(-50%);
  }

  .u-rounded-circle & {
    padding-bottom: 100%; // equal width and height
    border-radius: 50%;
  }

  .u-border-radius & {
    border-radius: $border-radius-base;
  }
}
