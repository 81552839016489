$m-fulfillment-widget-gap: 8px !default;
$m-fulfillment-widget-margin-block: $base-spacing !default;
$m-fulfillment-widget-border-radius: $border-radius-base !default;
$m-fulfillment-widget-padding: 8px !default;

.m-fulfillment-widget {
  display: grid;
  margin-block: ($m-fulfillment-widget-margin-block / 2);
  padding: $m-fulfillment-widget-padding;
  background-color: var(--color-frame-bg, #f5f5f5);
  border-radius: $m-fulfillment-widget-border-radius;
  gap: $m-fulfillment-widget-gap;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: "method method method" "address address time";

  @media screen and (min-width: $grid-float-breakpoint) {
    margin-block: $m-fulfillment-widget-margin-block;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas: "method method method address address time";
  }

  // TODO: Refactor this to container style queries in the future
  // Docs: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_containment/Container_size_and_style_queries#container_style_queries_2
  &--no-time-btn {
    grid-template-areas: "method method method" "address address address";

    @media screen and (min-width: $grid-float-breakpoint) {
      grid-template-areas: "method method method address address address";
    }
  }

  &__method {
    grid-area: method;
  }

  &__address {
    grid-area: address;
  }

  &__time {
    grid-area: time;
  }

  &__btn {
    @include text-ellipsis();
    width: 100%;
    min-height: $input-height-base;
    padding: 8px;
    background-color: var(--form-control-bg, $input-bg);
    border-width: 0;
    border-radius: $border-radius-base;
    text-align: start;
    font-family: inherit;

    &[disabled] {
      cursor: $cursor-disabled;
      opacity: $inactive-opacity;
    }
  }

  &__btn-icon {
    margin-inline-end: 8px;
    color: var(--color-primary);
    font-size: 22px;
    line-height: 1;
  }

  &__btn-text {
    color: var(--form-control-text-color, $input-color);
    font-size: $font-size-base;
    font-weight: $font-weight-base;
  }
}
