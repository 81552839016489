// Bootstrap overrides - START

.dropdown-menu {
  padding: 10px 0;
  border-radius: 0;
  box-shadow: none;

  > li {
    > a {
      padding: 7px 20px;
      font-weight: inherit;
    }
  }
}

// Bootstrap overrides - STOP
