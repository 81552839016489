.m-teasers {
  &__list {
    @include list-unstyled;
    margin: 0 auto;
    display: flex;
    align-items: stretch;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__list-item {
    position: relative;
    z-index: $zindex-ui;
    width: 50%;
    // background-color: $body-bg; // defined in theme.scss.liquid
    color: inherit;

    &:nth-child(2n+2) {
      top: -20px;
    }

    @media screen and (min-width: $grid-float-breakpoint) {
      width: 25%;

      &:nth-child(2n+2) {
        top: auto; // set to initial value (override mobile)
      }

      &:nth-child(4n+2) {
        top: -20px;
      }

      &:nth-child(4n+3) {
        top: 20px;
      }
    }

    &--action {
      @include pasja-box-shadow(10px);
      z-index: abs($zindex-ui - 1);
      //background-color: #fff; // defined in theme.scss.liquid
    }
  }

  &__item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    padding: floor($grid-gutter-height * 1.2) floor($grid-gutter-width / 2);
    text-transform: uppercase;
    transform: translateZ(0);

    @media screen and (min-width: $grid-float-breakpoint) {
      padding-top: floor($grid-gutter-width / 2);
      padding-bottom: floor($grid-gutter-width / 2);
      height: 235px;
    }

    &,
    &:hover,
    &:focus {
      text-decoration: none;
      transition: transform $base-duration;
    }

    &:hover,
    &:focus {
      .m-teasers__item-image {
        @include pasja-blend-mode(true);
      }

      .m-teasers__item-title {
        transform: scale(1.1);
      }
    }

    &:focus {
      outline: $outline-default;
      outline-offset: -2px;
      @include tab-focus();
    }
  }

  &__item-image {
    @include position(absolute, 0 0 0 0);
    @include pasja-blend-mode();
    background-position: 50% 50%;
    background-size: cover;
    transition: all $base-duration;
    transform: translateZ(0);
  }

  &__stamp {
    @include position(absolute, 0 null null 0);
    display: none;
    height: 20px;
    margin: auto;
    width: 10%;
    visibility: hidden;

    @media screen and (min-width: $grid-float-breakpoint) {
      display: block;
    }

    &--2 {
      left: 50%;
      height: 40px;
    }

    &--3 {
      right: 0;
      left: auto;
    }
  }

  &__item-title {
    position: relative;
    margin: 0;
    width: 100%;
    font-size: 2rem;
    color: inherit;
    word-wrap: break-word;
    hyphens: auto;
    transition: transform $base-duration;

    @at-root .l-page-header-ls & {
      letter-spacing: $pasja-letter-spacing;
    }

    &--image {
      color: #fff;
    }
  }

  &__item-action {
    @include position(absolute, null floor($grid-gutter-width / 2) floor($grid-gutter-width / 2) null);
    font-size: 1rem;
  }
}
