.cookie-warning {
  position: fixed;
  text-align: center;
  width: 100%;
  left: 0;
  bottom: 0;
  background: rgba(0,0,0,0.8);
  color: #fff;
  font-size: 12px;
  z-index: $zindex-cookie-warning;
  padding: 6px;


  &.hide {
    display: none;
  }

  &__btn {
    margin-left: .5em;
    padding: .2em .5em;
    min-height: 25px;
  }
}
