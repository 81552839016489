// Styles for PS checkout implementation
// TODO: Refactor checkout styles (base & themes specific) after remove PS implementation
.m-checkout {
  margin-bottom: 30px;

  .l-mobile-app--ios & {
    margin-bottom: 0;
  }

  &__cols {
    display: flex;
    flex-flow: column nowrap;

    @media screen and (min-width: $screen-sm-min) {
      flex-flow: row nowrap;
      align-items: flex-start;
    }
  }

  .column-form {
    width: auto;
    flex: 1 1 63%; // flex-basis required by IE 11
    order: 2;

    @media screen and (min-width: $screen-sm-min) {
      margin-right: 2%;
    }
  }

  .column-summary {
    width: auto;

    @media screen and (min-width: $screen-sm-min) {
      flex: 1 1 auto;
      width: 35%;
      order: 3;
    }

    .price {
      text-align: right;
      white-space: nowrap;
    }

    // Hide summary view in checkout (whole cart view is just a one step before).
    @at-root .l-mobile-app .column-summary {
      display: none;
    }
  }

  .summary-view {
    th,
    td {
      text-align: center;

      &:first-child {
        @include text-break();
        padding-left: 0;
        text-align: left;
      }

      &:last-child {
        padding-right: 0;
        text-align: right;
      }
    }
  }

  .form-actions {
    @at-root .l-mobile-app--ios .m-checkout .form-actions {
      margin-top: $grid-gutter-height;
    }
  }
}

// New styles for Preact implementation
.m-checkout {
  .m-checkout--redesigned {
    margin-top: map-get($spacers, 5);

    .m-checkout__section {
      @extend .card !optional;
      margin-bottom: $grid-gutter-width;
    }

    .m-checkout__section-title {
      margin: 0 0 map-get($spacers, 3);

      &--aside {
        flex: 1 1 auto;
        margin-right: map-get($spacers, 5);
      }
    }

    @media screen and (min-width: $screen-md-min) {
      .column-summary {
        position: sticky;
        top: $grid-gutter-width;
      }
    }

    // Static, white bg for payment icons to be sure that they are visible in dark themes.
    .m-radio__img-container,
    .m-edit-block__image {
      background: #ffffff;
      border-radius: 4px;
      padding: map-get($spacers, 1) map-get($spacers, 2);
    }
  }
}
