// Splide carousel - shared features and fixes.
.splide {
  // Required for animation.
  visibility: visible;

  &--rwd {
    .splide__list {
      display: flex;
      flex-direction: column;

      @media screen and (min-width: $screen-sm-min) {
        flex-direction: row;
      }
    }
  }

  &__arrow {
    &[disabled] {
      opacity: $inactive-opacity;
      cursor: $cursor-disabled;
    }
  }

  &__pagination {
    padding-left: 0; // reset `ul` default padding.

    // Make sure the single page indicator is hidden.
    &:has(li:only-child) {
      display: none;
    }

    // Fallback for browsers that don't support `has` selector.
    li:only-child {
      display: none;
    }
  }
}
