$m-popover-medium-max-width: 360px !default;

.m-popover {
  @extend .popover;

  &__dismiss {
    margin: 0;
    text-align: right;

    .close {
      float: none;
    }
  }

  // Styles from `m-availability`.
  &__title {
    font-size: 14px;
    font-weight: 600;
  }

  &__content {
    line-height: $line-height-base;
  }

  &__row {
    padding-top: ceil($grid-gutter-width / 2);
    padding-bottom: ceil($grid-gutter-width / 2);
    border-top: 1px solid $separator-color;

    &:first-child {
      padding-top: 0;
      border-top: 0;
    }
  }

  @at-root .promotion-popover__availability, // support legacy class name shared within various themes
  &__responsive {
    width: 100%;
    overflow-x: auto;
    min-height: 0.01%;

    > * {
      margin-bottom: 0;
    }
  }

  &--medium {
    max-width: $m-popover-medium-max-width;
  }

  &--auto {
    max-width: 99vw;
    width: auto;
  }
}
