.m-gallery {
  &__list {
    margin: 0;
    padding-left: 0;
    list-style: none;

    &--grid {
      // Grid gutter width is defined in theme.scss.liquid
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .m-gallery__list-item {
        flex: 0 1 auto;

        @media screen and (min-width: $screen-xs-min) {
          max-width: floor(100% / 2);
        }
      }

      .m-gallery__title {
        text-align: center;
      }

      .m-gallery__description {
        text-align: center;
      }

      .m-gallery__break-line {
        @media screen and (min-width: 1366px) { // it should be the same value as in `theme.scss.liquid`
          display: block;
          width: 100%;
          height: 1px;
          visibility: hidden;
        }
      }
    }

    &--list {
      .m-gallery__item {
        @media screen and (min-width: $grid-float-breakpoint) {
          display: flex;
        }
      }

      .m-gallery__image-link {
        flex-shrink: 0;
      }

      .m-gallery__image {
        @media screen and (max-width: $grid-float-breakpoint-max) {
          width: auto;
        }
      }

      .m-gallery__caption {
        flex-grow: 1;

        @media screen and (min-width: $grid-float-breakpoint) {
          margin-left: $grid-gutter-width;
        }
      }

      .m-gallery__title {
        @media screen and (min-width: $grid-float-breakpoint) {
          margin-top: 0;
        }
      }
    }
  }

  &__list-item {
    margin-bottom: $grid-gutter-height;
  }

  &__break-line {
    display: none;
  }

  &__item {
    position: relative;
  }

  &__image-link {
    position: relative;
    display: block;
    transform: translateZ(0);

    &:hover {
      z-index: $zindex-ui;

      .m-gallery__caption-overlay {
        &--transparency {
          opacity: 1;
        }

        &--zoom {
          opacity: 1;
          transform: scale(1);

          &.m-gallery__caption-overlay--center {
            transform: scale(1) translateX(-50%) translateY(-50%);
          }
        }

        &--rotate {
          opacity: 1;
          transform: rotate(360deg);

          &.m-gallery__caption-overlay--center {
            transform: rotate(360deg) translateX(-50%) translateY(-50%);
          }
        }

        &--slide {
          opacity: 1;
          transform: translateY(0);

          &.m-gallery__caption-overlay--center {
            transform: translateX(-50%) translateY(-50%);
          }
        }
      }
    }

    &--zoom {
      transition: transform $base-duration;
      transform: translateZ(0);

      &:hover {
        transform: scale(1.15) translateZ(10px);
      }
    }

    &--transparency {
      transition: opacity $base-duration;

      &:hover {
        opacity: $hover-opacity;
      }
    }

    &--pulse {
      &:hover {
        animation: pulse $base-duration;
      }
    }
  }

  &__image {
    width: 100%;
    transform: translateZ(0);
  }

  &__caption-overlay {
    @include position(absolute, null 0 0 0);
    padding-right: 1em;
    padding-left: 1em;
    font-size: 1em;
    color: #fff;
    text-align: center;
    background-color: rgba(#000, 0.6);
    transform: translateZ(0);
    transition: transform $base-duration, opacity $base-duration;

    .m-gallery__title,
    .m-gallery__description {
      margin-top: 1em;
      margin-bottom: 1em;
      font-size: inherit;
      color: inherit;
    }

    &--top {
      top: 0;
      bottom: auto;
    }

    &--center {
      @include position(absolute, 50% auto auto 50%);
      max-width: 90%;
      transform: translateX(-50%) translateY(-50%);
    }

    &--transparency {
      opacity: 0;
    }

    &--zoom {
      opacity: 0;
      transform: scale(0);

      &.m-gallery__caption-overlay--center {
        transform: scale(0) translateX(-50%) translateY(-50%);
        transform-origin: 0 0;
      }
    }

    &--rotate {
      opacity: 0;
      transform: rotate(0);

      &.m-gallery__caption-overlay--center {
        transform: rotate(0) translateX(-50%) translateY(-50%);
        transform-origin: 0 0;
      }
    }

    &--slide {
      opacity: 0;
      transform: translateY(100%);

      &.m-gallery__caption-overlay--center {
        transform: translateX(-50%) translateY(50%);
      }
    }
  }

  &__title {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  &__description {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  &__action {
    margin-top: 1em;
    margin-bottom: 1em;
    text-align: right;
  }
}
