// Shared imports for website themes (excluding active_menu and mobile_app)
@import "../base/splide-ext";
@import "../components/layout";
@import "../components/menu";
@import "../components/notifications";
@import "../components/cards";
@import "../components/image_popover";
@import "../components/facebook_like_box";
@import "../components/wysiwyg";
@import "../components/cookie_warning";
@import "../components/mobile_navigation";
@import "../components/restaurant_list";
@import "../modules/m-nav-toggle";
@import "../modules/m-popover";
@import "../modules/m-language-select";
@import "../modules/m-hr";
@import "../modules/m-gallery";
@import "../modules/m-gallery-carousel";
@import "../modules/m-restaurant-hours";
@import "../modules/m-flex-layout";
@import "../modules/m-confirmation";
@import "../modules/m-footer-info";
@import "../modules/m-map";
@import "../modules/m-attention";
@import "../modules/m-promo-section";
@import "../modules/m-tabs";
@import "../modules/m-checkout";

.has-lightbox {
  // Avoiding visibility conflicts between primary content (<main>) and role=dialog layer of document
  main {
    z-index: $zindex-ui;
  }
}
