$icon-width: 26px;
$image-width: 60px;
$image-height: 30px;
$column-gap: ceil($base-spacing / 3);

.m-edit-block {
  display: flex;
  align-items: center;
  min-height: $input-height-base;
  padding: $padding-base-vertical $padding-base-horizontal;
  border: $input-border-shorthand;
  border-radius: $input-border-radius;
  background-color: $input-bg;
  box-shadow: $input-box-shadow;

  &--button {
    width: 100%;
    text-align: inherit;
    font-family: inherit;
    text-decoration: inherit;

    &:focus-visible {
      border-color: var(--color-primary, $brand-primary);
      outline: none;
      box-shadow: none;
    }
  }

  &.has-error {
    border-color: var(--text-danger, #{$state-danger-text});
  }

  &__image {
    flex: 0 0 $image-width;

    img {
      max-width: 100%;
      max-height: $image-height;
      margin: 0 auto;
    }
  }

  &__icon {
    flex: 0 0 $icon-width;
    align-self: flex-start;
    text-align: center;
    font-size: 1.3em;
    color: inherit;

    &--centered {
      align-self: center;
    }
  }

  &__image,
  &__icon {
    margin: 0 $column-gap 0 0;
  }

  &__content {
    flex: 1 1 auto;
    margin: ceil($base-spacing / 4) 0;
    min-width: 0;
  }

  &__title {
    @include text-break();
    font-family: inherit;
    font-size: 1.1em;
    font-weight: $font-weight-bold;
    margin: 0;
  }

  &__description {
    font-size: inherit;
    margin: map-get($spacers, 1) 0 0 0;
    white-space: pre-line; // to allow using "\n" in description string
    overflow-wrap: break-word;
  }

  &__footer {
    font-size: 0.95em;
    margin: ceil($base-spacing / 4) 0 0;
    color: var(--text-muted, #{$text-muted});
  }

  &__description,
  &__footer {
    line-height: 1.3;
  }

  &__action {
    flex: 0 0 auto;
    margin: 0 0 0 $column-gap;
  }
}
