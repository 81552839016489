$m-switch-min-height: $input-height-base !default;
$m-switch-border-radius: $border-radius-base !default;
$m-switch-padding: 4px !default;
$m-switch-item-padding: 8px !default;
$m-switch-label-font-size: $font-size-base !default;

.m-switch {
  position: relative;
  overflow: hidden;
  display: flex;
  min-height: $m-switch-min-height;
  height: 100%;
  padding: $m-switch-padding;
  background-color: var(--form-control-bg, $input-bg);
  border-radius: $m-switch-border-radius;

  // Always assign value to --switch-items-number var when using `m-switch` component. It's required to handle switch animation
  // Example in `client/js/components/Switch.tsx`
  &::after {
    position: absolute;
    content: "";
    width: calc(
      (100% - (2 * #{$m-switch-padding})) / var(--switch-items-number)
    );
    height: calc(100% - (2 * #{$m-switch-padding}));
    z-index: 0;
    background-color: var(--color-primary);
    border-radius: $m-switch-border-radius;
    transition: transform $base-duration;
  }

  // Animation assumes there will be a maximum 5 switch items
  &:has(.m-switch__item.is-active:nth-of-type(1))::after {
    transform: translateX(0);
  }

  &:has(.m-switch__item.is-active:nth-of-type(2))::after {
    transform: translateX(100%);
  }

  &:has(.m-switch__item.is-active:nth-of-type(3))::after {
    transform: translateX(200%);
  }

  &:has(.m-switch__item.is-active:nth-of-type(4))::after {
    transform: translateX(300%);
  }

  &:has(.m-switch__item.is-active:nth-of-type(5))::after {
    transform: translateX(400%);
  }

  &:has(input:focus-visible) {
    outline: 2px solid var(--color-primary, #000);
  }

  &__item {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: $m-switch-item-padding;
    flex: 1;
    z-index: 1;
    border-radius: $m-switch-border-radius;
    color: var(--color-primary);
    cursor: pointer;
    transition: color $base-duration;

    &.is-active {
      color: var(--color-text-on-primary-bg, #fff);
    }

    &.is-disabled {
      cursor: $cursor-disabled;
      opacity: $inactive-opacity;
    }
  }

  &__input {
    @include visually-hidden();
  }

  &__label {
    @include line-clamp(2);
    margin: revert;
    padding-block: 2px;
    font-size: $m-switch-label-font-size;
    font-weight: $font-weight-bold;
    text-align: center;
    word-break: break-word;
    line-height: 1;
    cursor: pointer;
  }
}
