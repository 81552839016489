$m-steps-zindex: $zindex-ui !default;
$m-steps-bg-color: #fff !default;
$m-steps-mask-patter-color: $body-bg !default;

.m-steps {
  position: relative;
  z-index: $m-steps-zindex;
  padding: 140px 0 80px;
  text-align: center;
  background-color: $m-steps-bg-color;

  &:before {
    @include position(absolute, 0 0 0 0);
    z-index: 1;
    background: $m-steps-mask-patter-color repeat; // color defined in theme.scss.liquid
    mask-image: url("../images/pasja/b69d9be70ca17e683a33982d6a48d1b1.svg");
    content: " ";

    // IE 10 and 11 fallback
    @media screen and (-ms-high-contrast: none) {
      background: $m-steps-bg-color url("../images/pasja/b69d9be70ca17e683a33982d6a48d1b1.svg") repeat; // color defined in theme.scss.liquid
      opacity: 0.05;
    }

    @supports not (mask-image: url("../images/pasja/b69d9be70ca17e683a33982d6a48d1b1.svg")) { // For some reason it doesn't work correctly on local instance with protocol prefix.
      background: $m-steps-bg-color url("../images/pasja/b69d9be70ca17e683a33982d6a48d1b1.svg") repeat;
      opacity: 0.05;
    }
  }

  &:after {
    @include position(absolute, 15% 0 0 0);
    z-index: 2;
    background-image: linear-gradient(to top, $m-steps-bg-color 0%, $m-steps-bg-color 60%, rgba($m-steps-bg-color, 0) 100%);
    content: " ";
  }

  &__header {
    position: relative;
    z-index: $m-steps-zindex;
    margin: 0 auto;

    @media screen and (min-width: $grid-float-breakpoint) {
      width: 40%;
    }

    .m-page-header {
      margin-bottom: 30px;
    }
  }

  &__list {
    @include list-unstyled;
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    @media screen and (min-width: $grid-float-breakpoint) {
      flex-wrap: nowrap;
    }
  }

  &__list-item {
    position: relative;
    z-index: ($m-steps-zindex - 10);
    flex: 1 1 auto;

    @media screen and (min-width: $grid-float-breakpoint) {
      flex: 0 0 auto;

      &:first-child {
        top: -70px;

        .m-steps__icon-badge {
          @include position(absolute, auto -15px 27px auto);
        }
      }

      &:last-child {
        top: -70px;

        .m-steps__icon-badge {
          @include position(absolute, auto auto 0 -10px);
        }
      }
    }
  }

  &__item {
    margin: 0 auto;
    padding: $base-spacing floor($grid-gutter-width / 2);
    max-width: 280px;
    background-color: $m-steps-bg-color;
    box-shadow: 0 0 $base-spacing $m-steps-bg-color;
    border-radius: 50% / 60% 60% 40% 40%;
  }

  &__icon {
    display: inline-block;
    position: relative;
    margin-bottom: ($line-height-computed / 2);
  }

  &__icon-badge {
    @include position(absolute, 5px 0 null null);
    display: inline-block;
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    background-color: var(--color-primary);
    color: var(--color-text-on-primary-bg);
    border-radius: 50%;
  }

  &__item-separator {
    display: none;

    @media screen and (min-width: $grid-float-breakpoint) {
      @include position(absolute, ($base-spacing / -2) 80% null null);
      display: block;
      padding-top: 15px;
      padding-bottom: 15px;
      width: 100px;
      //color: $brand-secondary; // defined in theme.scss.liquid
      overflow: hidden;
      background-color: $m-steps-bg-color;
      border-radius: 0 100% 0 100%;
      box-shadow: 0 0 $base-spacing $m-steps-bg-color;
    }

    @media screen and (min-width: $screen-md-min) {
      width: auto;
    }

    &--prev {
      @media screen and (min-width: $grid-float-breakpoint) {
        @include position(absolute, ($base-spacing + 25) 85% null null);
        border-radius: 100% 0 100% 0;
      }
    }
  }

  &__title {
    margin-top: ($line-height-computed / 2);
    margin-bottom: ($line-height-computed / 2);
    font-size: 1.125rem;
    text-transform: uppercase;

    @at-root .l-page-header-ls & {
      letter-spacing: ($pasja-letter-spacing * 0.55);
    }
  }

  &__action {
    position: relative;
    z-index: $m-steps-zindex;
  }
}
