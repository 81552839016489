$tips-input-horizontal-spacing: min($padding-base-horizontal * 2, 30px);
$tips-input-height: min(floor($input-height-base * 2), 100px);
$tips-input-font-size: 2.6rem;

.m-tips {
  display: flex;
  gap: floor($grid-gutter-width / 2);
  margin-bottom: map-get($spacers, 2);
}

// Tip option box based on the `m-radio--button`
.m-tips-option {
  width: 33%;
  min-height: $input-height-base;
  border: $input-border-shorthand;
  border-radius: $input-border-radius;
  background-color: $input-bg;
  box-shadow: $input-box-shadow;
  cursor: pointer;

  &:focus-within,
  &.is-active {
    border-color: var(--color-primary, $brand-primary);
  }

  &__input {
    position: absolute;
    opacity: 0; // hide native radio
  }

  &__label {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: ($padding-base-vertical * 2) $padding-base-horizontal;
    margin-bottom: 0;
    text-align: center;
    line-height: 120%;
    font-weight: $m-radio-font-weight;
    cursor: inherit;
  }

  &__description {
    color: var(--text-muted);
    font-size: 0.8em;
  }
}

// Custom tip amount modal
.m-tips-modal {
  @media (min-width: $screen-sm-min) {
    max-width: 350px;
  }

  &__field {
    // Default value for PL currency symbol (zł) that should be updated in HTML by logic.
    --tips-input-suffix-width: 2ch;

    display: block;
    position: relative;
    align-items: center;
    height: $tips-input-height;
    max-width: calc(
      #{(140px + $tips-input-horizontal-spacing * 2)} +
        var(--tips-input-suffix-width)
    );
    margin-inline: auto;
  }

  // "with-suffix" hack to to bump specifcity... (see behemot theme)
  &__input.with-suffix {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    padding-left: $tips-input-horizontal-spacing;
    padding-right: calc(
      #{$tips-input-horizontal-spacing} + var(--tips-input-suffix-width)
    );

    // Remove stepper arrows in number input
    -moz-appearance: textfield;
    appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }

  &__input-suffix {
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    right: $tips-input-horizontal-spacing;
    width: var(--tips-input-suffix-width);
    line-height: $tips-input-height;
    pointer-events: none;
    text-align: right;
  }

  &__field,
  &__input,
  &__input-suffix {
    font-weight: $font-weight-bold;
    font-size: $tips-input-font-size;
  }
}
