$card-margin-bottom: 20px !default;
$card-padding: 20px !default;
$card-background: white !default;
$card-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !default;

.card {
  background: $card-background;
  padding: $card-padding;
  box-sizing: border-box;
  margin-bottom: $card-margin-bottom;
  box-shadow: $card-box-shadow;

  header.full-bleed {
    margin-top: -20px;
  }
}

.full-bleed {
  margin-left: -20px;
  margin-right: -20px;
}
